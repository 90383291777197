import { Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Transition, Dialog } from '@headlessui/react'
import { gql, useQuery, ApolloError, useMutation } from "@apollo/client"
import { ChevronDownIcon, EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Connection, User, UserPaymentMethods } from '../user/User.type';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../Context';
import Me from '../../Me';
import { Transaction } from '../expense/Expense.type';
import { GraphQLError } from "graphql/error";
import NotificationR from "../NotificationR";
import SendReminderConfirmation from './ReminderConfirmation';
import Loading from '../Loading';
import SettleTransactions from './SettleTransactions';
import { ExpenseUser } from "../expense/Expense.type";




export const QUERY = gql`
  query Transactions($filterBy: TransactionFilterInput) {
    transactions(filterBy: $filterBy) {
      amountPayable
      amountReceivable
      netAmountOwed
  }
}
`

interface StandardPaymentMethod {
  username?: string;
  emailornumber: string;
  image?: string;
}

interface OtherPaymentMethod {
  value: string;
  name: string;
}

interface PaymentData {
  [key: string]: StandardPaymentMethod | OtherPaymentMethod[];
}


const USERTRANSACTIONSQUERY = gql`
  query Transactions($filterBy: TransactionFilterInput) {
    transactions(filterBy: $filterBy) {
      amountPayable
      amountReceivable
      netAmountOwed
      transactions {
        id
        amountOwed
        amountPaid
        fromUser {
          id
          name
          phone
          countryCode
        }
        toUser {
          id
          name
          phone
          countryCode
        }
        expense {
          id
          name
          shortCode
          totalAmount
        }
      }
  }
}
`

const MUTATION = gql`
mutation SendReminderForNetAmountOwed($input: SendReminderForNetAmountOwedInput!){
  sendReminderForNetAmountOwed(input: $input){
    code
    message
    notification
    success
  }
}
`

export default function Users() {
  const navigate = useNavigate();
  const context = useContext(UserContext);

  const user: User = context.user;
  const [connections, setConnections] = useState<Connection[]>([]);
  const [displayExpenses, setDisplayExpenses] = useState<{ id: string, show: boolean }[]>([]);
  const [message, setMessage] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [confirmationUserName, setConfirmationUserName] = useState<string | undefined>("")
  const [confirmationAmount, setConfirmationAmount] = useState<number>(0)
  const [fromUserId, setfromUserId] = useState<string>()
  const [isSuccess, setIsSuccess] = useState<boolean>(true)

  const [SendReminderForNetAmountOwed] = useMutation(MUTATION);

  useEffect(() => {
    if (user && user.connections) {
      setConnections(user.connections);
      user.connections.map((c, index) => {
        displayExpenses.push({ id: c.contact.id, show: false });
      });
    }

  }, [user]);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  const sendReminderF = async () => {
    let dataReminder;

    try {
      dataReminder = await SendReminderForNetAmountOwed({
        variables: {
          input: {
            fromUserId: Number(fromUserId),
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) => {
        setMessage(graphQLError.message)
        setIsSuccess(false)
      }
      );
    }
    if (dataReminder?.data?.sendReminderForNetAmountOwed?.success) {
      setIsSuccess(true)
      setMessage(dataReminder?.data.sendReminderForNetAmountOwed.message);
    } else {
      setIsSuccess(false)
      setMessage(dataReminder?.data.sendReminderForNetAmountOwed.message);
    }
  };

  const createExpense = async () => {
    navigate(`/createExpense`);
  };

  function handleDisplayExpensesChange(id: string) {
    setDisplayExpenses(displayExpenses.map((e, index) => {
      if (e.id === id) {
        return { id: e.id, show: !e.show };
      }
      return e;
    }));
  }

  const customCSS = `
    @keyframes slideDown {
      0% {
        opacity: 0;
        transform: translateY(-9%);
        
      }
      100% {
        opacity: 1;
        transform: translateY(0);
        

      }
    }
    @keyframes borderFadeIn {
     
      0% {
        opacity: 0;
        transform: scaleX(-9%);  
  transition: transform 250ms ease-in-out;
      }
      100% {
        opacity: 1;
        transform: scaleX(0);  
  transition: transform 250ms ease-in-out;

      }
    }

    .animate-border{
      animation: borderFadeIn  ; 

    }

    /* Apply animation to the dropdown */
    .animate-slide-down-custom {
      animation: slideDown 0.9s ease-in-out ; 
    }
  `;

  return (
    <div className='ml-2 mr-2 grid grid-cols-1 gap-x-6 gap-y-2 lg:grid-cols-3 xl:gap-x-8'>
      <style>{customCSS}</style>
      <div className="overflow-hidden rounded-xl ">
        {message && (
          <NotificationR message={message} setMessage={setMessage} isSuccess={!isSuccess} />
        )}
        <Me />

        {showConfirmationDialog && <SendReminderConfirmation name={confirmationUserName} amount={confirmationAmount} onConfirmation={sendReminderF} onCancel={setShowConfirmationDialog} />}

        <div className="">
          {(connections ? connections.length : 0) === 0 && (
            <div className="text-center">
              <h3 className="mt-12 text-sm font-semibold text-gray-900">No expenses</h3>
              <p className="mt-1 text-sm text-gray-500">Get started by creating a new expense.</p>
              <div className="mt-6 mb-6">
                <button
                  type="button"
                  onClick={createExpense}
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                  New expense
                </button>
              </div>
            </div>
          )}
          {(connections ? connections.length : 0) > 0 && (

            <div className='space-y-2'>
              {connections.filter((c) => (c.settlements.netAmountOwed != 0)).map((u) => (
                <div className='border rounded-xl '>
                  <div key={u.contact.id} className={`rounded-xl min-w-full  flex items-center  border-gray-200 ${(!displayExpenses.find((e) => e.id === u.contact.id)?.show) ? ' shadow-sm' : 'animate-border rounded-es-none rounded-ee-none'} `} >
                    <div className="flex flex-grow" onClick={() => { navigate('/u/' + u.contact.phone) }}>
                      <div className="flex items-start gap-x-3">
                        <p className="px-4 text-sm font-normal leading-6 text-gray-900"  >
                          {u.contact.name}
                        </p>

                      </div>
                    </div>
                    <div className="py-5 text-right" >
                      <div className="flex justify-end">
                        <div
                          onClick={() => { handleDisplayExpensesChange(u.contact.id) }}
                          className={classNames(
                            displayExpenses.find((e) => e.id === u.contact.id)?.show ? "bg-gray-100" : u.settlements.netAmountOwed < 0 ? 'text-red-600 bg-red-50 ' : 'text-green-700 bg-green-100 ',

                            'flex items-start  rounded-full px-2.5 py-0.5 mr-5'
                          )}>
                          {!displayExpenses.find((e) => e.id === u.contact.id)?.show && (
                            <div className='inline-flex items-baseline  text-sm font-medium md:mt-2 lg:mt-0'>
                              {u.settlements.netAmountOwed}

                            </div>)}
                          <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                        </div>

                        <div className="flex flex-none items-center gap-x-4 absolute mt-0.5">
                          <Menu as="div" className="relative flex-none">
                            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                              <span className="sr-only">Open options</span>
                              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-32  origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() => { navigate('/u/' + u.contact.phone) }}
                                      className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                      )}
                                    >
                                      Details<span className="sr-only">, {u.contact.name}</span>
                                    </button>
                                  )}
                                </Menu.Item>
                                {u.settlements.netAmountOwed > 0 && <Menu.Item >
                                  {({ active }) => (
                                    <button
                                      onClick={() => {
                                        setfromUserId(u.contact.id)
                                        setShowConfirmationDialog(true);
                                        setConfirmationUserName(u.contact.name)
                                        setConfirmationAmount(u.settlements.netAmountOwed)
                                      }}
                                      className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                      )}
                                    >
                                      Send Reminder<span className="sr-only">, {u.contact.name}</span>
                                    </button>
                                  )}
                                </Menu.Item>}
                              </Menu.Items>

                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>


                  {displayExpenses.find((e) => e.id === u.contact.id)?.show && (
                    <div className=' animate-slide-down-custom border-gray-200 rounded-b-xl shadow-lg'>
                      <div style={{ transition: "ease-in" }} className="-mt-3 transform animate-slide-down-custom">
                        <InlineExpenses setfromUserId={setfromUserId} setShowConfirmationDialog={setShowConfirmationDialog} setConfirmationUserName={setConfirmationUserName} setConfirmationAmount={setConfirmationAmount} connection={u} message={message} setMessage={setMessage} />
                      </div>
                    </div>
                  )}

                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}


function InlineExpenses(props: { connection: Connection, message: string, setMessage: React.Dispatch<React.SetStateAction<string>>, setfromUserId: React.Dispatch<React.SetStateAction<string | undefined>>, setShowConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>, setConfirmationUserName: React.Dispatch<React.SetStateAction<string | undefined>>, setConfirmationAmount: React.Dispatch<React.SetStateAction<number>> }) {
  const { message, setMessage } = props;
  const context = useContext(UserContext);
  const [transactions, setTransactions] = useState<(Transaction)[]>([]);
  const [showSettleDialog, setShowSettleDialog] = useState(false);

  const user: User = context.user;
  const userProfile: any = user.contacts?.find((c) => (c.phone == props.connection.contact.phone));

  function getExpenseUser(u: User | undefined): ExpenseUser {
    return {
      id: u?.id,
      name: u?.name || '',
      phone: u?.phone || '',
      countryCode: u?.countryCode || 1,
    };
  }

  function callbackSettleDialog(message: string) {
    if (message === 'success') {
      setMessage("Settlement Successful");
    }
    setShowSettleDialog(false);
  }

  const { loading, error, data } = useQuery(USERTRANSACTIONSQUERY, {
    variables: {
      filterBy: {
        "transactionType": "ALL",
        "settlementType": "UNSETTLED",
        "filterByUserId": props.connection.contact.id
      }
    }
  });

  useEffect(() => {
    setTransactions(data?.transactions?.transactions);

  }, [data]);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  if (loading) return <Loading />

  return (
    <>
      {showSettleDialog && (
        <SettleTransactions settlement={{ transaction: data?.transactions, amount: data?.transactions?.netAmountOwed, fromUser: user, toUser: getExpenseUser(userProfile) }} notify={callbackSettleDialog} />
      )}
      <div className='pb-4'>
        {transactions && transactions.map((t) => (
          <div key={t.id} className="flex items-baseline justify-between border-gray-200 pl-4 min-w-0">
            <span className="font-normal text-gray-900 text-xs pl-1 pb-0.5">{t.expense?.name}</span>
            <div className={classNames(
              t.fromUser?.id === context.user.id ? 'text-red-600' : 'text-green-600',
              'inline-flex items-baseline rounded-full px-2.5 pr-8 text-xs font-normal'
            )}>
              {t.fromUser?.id === context.user.id ? -t.amountOwed : t.amountOwed}
            </div>
          </div>
        ))}

        <div className="border-t-2 border-dotted border-gray-400 mt-2 pt-2 flex items-baseline justify-between border-gray-200 pl-4 min-w-0 ">
          <span className="text-gray-900 text-sm font-semibold pl-1 pb-0.5">Total Amount</span>
          <div className={classNames(data?.transactions?.netAmountOwed > 0 ? 'text-green-600 ' : 'text-red-600 ',
            'inline-flex items-baseline rounded-full px-2.5 pr-8 text-sm font-semibold text-black-600'
          )}>
            {data?.transactions?.netAmountOwed}
          </div>
        </div>



        <div className='mx-2 mb-4 mt-3'>
          {data?.transactions?.netAmountOwed < 0 && userProfile?.paymentMethods && Object.keys(userProfile.paymentMethods).length !== 0 && <div >
            <PaymentMethods paymentData={userProfile.paymentMethods} setMessage={setMessage} />
          </div>
          }
        </div>


        <div className='mx-4 mb-2 flex flex-row gap-x-2'>
          <button
            type="button"
            onClick={() => { setShowSettleDialog(true) }}
            className="flex w-full border border-indigo-300 justify-center rounded-md bg-white-600 px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Settle Net Amount
          </button>
          {props.connection.settlements.netAmountOwed > 0 && <button
            type="button"
            onClick={() => {
              props.setShowConfirmationDialog(true)
              props.setfromUserId(props.connection.contact.id)
              props.setConfirmationUserName(props.connection.contact.name)
              props.setConfirmationAmount(props.connection.settlements.netAmountOwed)
            }}
            className="flex w-full border border-indigo-300 justify-center rounded-md bg-white-600 px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Send Reminder
          </button>}
        </div>

        <div>

        </div>
      </div>
    </>
  )
}

const PaymentMethods: React.FC<{ paymentData: PaymentData, setMessage: React.Dispatch<React.SetStateAction<string>> }> = ({ paymentData, setMessage }) => {

  return (
    <div className="mt-2 flex ml-1 space-x-7">
      {Object.entries(paymentData).map(([key, value], index) => (
        <div key={index} className='flex h-6 items-center '>
          {Array.isArray(value) ? (
            <div>
              {value?.length > 0 && (
                <div className="flex mb-2 px-2 h-6 ">
                  <img
                    onClick={() => {
                      navigator.clipboard.writeText(value[0].value)
                        .then(() => {
                          setMessage("Copied to clipboard!")
                        })
                        .catch(err => {
                          console.error('Failed to copy: ', err);
                        });
                    }}
                    src="https://cdn-icons-png.freepik.com/512/8749/8749431.png" alt={`${key} logo`} className=" h-5 w-5" />
                </div>
              )}
            </div>
          ) : (
            <div className=" flex mb-2 px-2 h-6 ">
              {value?.image && <img
                onClick={() => {
                  navigator.clipboard.writeText(value.username ?? value.emailornumber)
                    .then(() => {
                      setMessage("Copied to clipboard!")
                    })
                    .catch(err => {
                      console.error('Failed to copy: ', err);
                    });
                }}
                src={value?.image} alt={`${key} logo`} className=" h-5 w-5" />}
            </div>
          )}
        </div>
      ))
      }
    </div >
  );
};
