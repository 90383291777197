import React, { useEffect, useRef } from "react";
import { UserContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import { ExpenseUser, Transaction } from "./Expense.type";
import { gql, useMutation, ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import Select from "react-tailwindcss-select";
import Header from "../Header";
import { Connection, User } from "../user/User.type";
import SimpleReactValidator from 'simple-react-validator';
import ListTransactions from "../transactions/ListTransactions";

interface UserOption {
  label: string;
  value: string;
  disabled?: boolean;
  user?: ExpenseUser;
}

const userOptionClass: UserOption = {
  label: "",
  value: "",
  disabled: false,
};

const MUTATION = gql`
  mutation CreateExpense($input: CreateExpenseInput!) {
    createExpense(input: $input) {
      code
      message
      expense {
        id
        shortCode
        user {
          id
          name
          phone
          contacts {
            name
            phone
            id
            countryCode
          }
          connections {
            contact {
              id
              name
              phone
              countryCode
            }
            settlements {
              netAmountOwed
              amountReceivable
              amountPayable
            }
          }
          settlements {
            netAmountOwed
            amountReceivable
            amountPayable
          }
        }
      }
      success
    }
  }
`;

const CreateExpense: React.FC = () => {
  const navigate = useNavigate();
  const context = React.useContext(UserContext);
  const user: User = context.user;


  const [message, setMessage] = React.useState("");
  const [expenseName, setExpenseName] = React.useState("");
  const [totalAmount, setTotalAmount] = React.useState<number | undefined>();

  const [options, setOptions] = React.useState<UserOption[]>([]);
  const simpleValidator = useRef(new SimpleReactValidator())


  const [showCreatePayable, setShowCreatePayable] = React.useState(false);
  const [showCreatePayee, setShowCreatePayee] = React.useState(false);

  const [participant, setParticipant] = React.useState<UserOption[]>([]);
  const [payable, setPayable] = React.useState<UserOption[]>([]);

  const [transactions, setTransactions] = React.useState<Transaction[]>([]);
  const [sendNotification, setSendNotification] = React.useState<boolean>(false)

  useEffect(() => {
    let o = context.user.connections?.map((connection: Connection) => {
      return { label: connection.contact.name || "", value: connection.contact.id || "" }
    });
    if (!(o?.some((option) => option?.value === context.user.id))) {
      o?.unshift({ label: context.user.name || "", value: context.user.id || "" });
    }

    setPayable([{ label: user.name as string, value: user.id, disabled: false, user: { id: user.id, name: user.name || "", phone: user.phone, countryCode: user.countryCode } }]);
    setParticipant([{ label: user.name as string, value: user.id, disabled: false, user: { id: user.id, name: user.name || "", phone: user.phone, countryCode: user.countryCode } }]);

    setOptions(o || []);
  }, []);

  const [createExpense] = useMutation(MUTATION);
  const forceUpdate = useForceUpdate();

  function useForceUpdate() {
    const [value, setValue] = React.useState(0);
    return () => setValue(value => value + 1);
  }
  const createExpenseF = async () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate();
      return;
    }
    let dataExpense;

    try {
      dataExpense = await createExpense({
        variables: {
          input: {
            name: expenseName,
            totalAmount,
            transactions,
            sendNotification,
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }

    if (dataExpense?.data?.createExpense) {
      setMessage("");
      context.updateUser(dataExpense?.data?.createExpense?.expense?.user as User);
      navigate("/e/" + dataExpense.data.createExpense?.expense?.shortCode);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      createExpenseF();
    }
  };

  const onCreatePayable = (contact: ExpenseUser) => {
    setOptions([...options, { label: contact.name, value: '', user: contact }]);
    setPayable([...payable, { label: contact.name, value: '', user: contact }]);
    updateTransactions(participant, [...payable, { label: contact.name, value: '', user: contact }], totalAmount ? totalAmount : 0);
    setShowCreatePayable(false);
  }

  const onCreatePayee = (contact: ExpenseUser) => {
    setOptions([...options, { label: contact.name, value: '', user: contact }]);
    setParticipant([...participant, { label: contact.name, value: '', user: contact }]);
    updateTransactions([...participant, { label: contact.name, value: '', user: contact }], payable, totalAmount ? totalAmount : 0);
    setShowCreatePayee(false);
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  }

  const onTotalAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(parseFloat(e.target.value))) {
      setTotalAmount(undefined);
      updateTransactions(participant, payable, 0);
      return;
    }
    setTotalAmount(parseFloat(e.target.value));
    updateTransactions(participant, payable, parseFloat(e.target.value));
  }

  const updateTransactions = (payees: UserOption[], payables: UserOption[], amount: number) => {
    if (!payees || !payables || payees.length === 0 || payables.length === 0) {
      return;
    }

    let amountPerPayable = (amount / payables.length);
    let amountDue = Number(((amountPerPayable) / payees.length).toFixed(2));

    const t = payees.map((user: UserOption): Transaction[] => {
      return payables.map((payableUser: UserOption): Transaction => {

        let amountPaid = 0;
        if (payable?.some((p) => p.value === user.value)) {
          amountPaid = amountDue;
        }

        let newTransaction = {
          amountOwed: amountDue,
          amountPaid: amountPaid,
          fromUserId: 0,
          fromCountryCode: 0,
          fromPhone: '',
          fromName: user.label,
          toUserId: 0,
          toCountryCode: 0,
          toPhone: '',
          toName: payableUser.label,
        };

        if (user.value === '') {
          newTransaction.fromPhone = user.user?.phone || '';
          newTransaction.fromCountryCode = user.user?.countryCode || 0;
        }
        else {
          newTransaction.fromUserId = parseInt(user.value);
        }

        if (payableUser.value === '') {
          newTransaction.toPhone = payableUser.user?.phone || '';
          newTransaction.toCountryCode = payableUser.user?.countryCode || 0;
        }
        else {
          newTransaction.toUserId = parseInt(payableUser.value);
        }

        return newTransaction;
      });
    });
    setTransactions(t.flat());
  }

  const handlePayeeChange = (payee: any) => {
    setParticipant(payee);
    updateTransactions(payee, payable, totalAmount ? totalAmount : 0);
  };

  const handlePayableChange = (payable: any) => {
    setPayable(payable);
    updateTransactions(participant, payable, totalAmount ? totalAmount : 0);
  }

  React.useEffect(() => {
    if (!context.user.id) {
      navigate("/login");
    }
  }, [context]);

  console.log("----sendNotification----", sendNotification)

  return (
    <div className="min-h-full">
      <Header />
      <form>
        <div className="space-y-12 px-4 mt-2">
          <div className="pb-12">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 py-4">New Expense</h1>
            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="expense-name"
                    id="expense-name"
                    value={expenseName}
                    onChange={(e) => setExpenseName(e.target.value)}
                    onBlur={() => simpleValidator.current.showMessageFor('name')}
                    className="block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="mt-2 text-sm text-red-600">
                  {simpleValidator.current.message('name', expenseName, 'required', { className: ' ' })}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="amount" className="block text-sm font-medium leading-6 text-gray-900">
                  Amount
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-expenses-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name="totalAmount"
                    pattern="\d*"
                    step="0.01"
                    inputMode="decimal"
                    id="totalAmount"
                    value={totalAmount}
                    onChange={onTotalAmountChange}
                    onBlur={() => simpleValidator.current.showMessageFor('totalAmount')}
                    autoComplete="totalAmount"
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0.00"
                  />
                </div>
                <div className="mt-2 text-sm text-red-600">
                  {simpleValidator.current.message('totalAmount', totalAmount, 'required', { className: ' ' })}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                  Who Paid?
                </label>

                <div className="mt-2 flex flex-grow items-stretch focus-within:z-10 gap-x-1.5">
                  <Select
                    primaryColor={"indigo"}
                    value={payable}
                    onChange={handlePayableChange}
                    isMultiple={true}
                    options={options}
                    isSearchable={true}
                  />
                  <button
                    type="button"
                    className="relative -ml-px whitespace-nowrap inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setShowCreatePayable(true)}
                  >
                    + New
                  </button>
                </div>
                {showCreatePayable && <CreateContact onCreateContact={onCreatePayable} />}
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                  Participants
                </label>
                <div className="mt-2 flex flex-grow items-stretch focus-within:z-10 gap-x-1.5 ">
                  <Select
                    primaryColor={"indigo"}
                    value={participant}
                    onChange={handlePayeeChange}
                    isMultiple={true}
                    placeholder="Select..."
                    options={options}
                    isSearchable={true}
                  />
                  <button
                    type="button"
                    className="relative -ml-px whitespace-nowrap inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setShowCreatePayee(true)}
                  >
                    + New
                  </button>
                </div>
                {showCreatePayee && <CreateContact onCreateContact={onCreatePayee} />}
                <div className="mt-4 sm:px-2 lg:px-8">
                  {transactions.length > 0 && <ListTransactions transactions={transactions} />}
                </div>
              </div>
            </div>
            <div>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={sendNotification}
                  onChange={() => setSendNotification(!sendNotification)}
                />

                <span className="ml-2 block text-sm font-medium leading-6 text-gray-800">Also notify users of this expense</span>
              </label>
            </div>

            <div className="mt-4">
              <button
                type="button"
                onClick={createExpenseF}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Submit
              </button>
            </div>
            <div className="mt-2">
              <button
                type="button"
                onClick={() => { navigate("/"); }}
                className="flex w-full justify-center rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};



function CreateContact(props: { onCreateContact: (contact: ExpenseUser) => void }) {

  const [newContactName, setNewContactName] = React.useState("");
  const [newContactPhone, setNewContactPhone] = React.useState("");
  const [countryCode, setCountryCode] = React.useState(1);

  const context = React.useContext(UserContext);
  const countryCodeOptions = context.countryCodes;

  const simpleValidator = useRef(new SimpleReactValidator())
  const forceUpdate = useForceUpdate();

  function useForceUpdate() {
    const [value, setValue] = React.useState(0);
    return () => setValue(value => value + 1);
  }
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate();
      return;
    }

    props.onCreateContact({
      name: newContactName,
      phone: newContactPhone,
      countryCode: countryCode,
    });
    newContactName && setNewContactName("");
    newContactPhone && setNewContactPhone("");
  }


  return (
    <div>

      <div className="mt-2 flex flex-grow items-stretch focus-within:z-10 gap-x-1.5 ">
        <div className="rounded-md px-3 pb-1.5 pt-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <input
            type="text"
            name="contactName"
            id="contactName"
            value={newContactName}
            onBlur={() => simpleValidator.current.showMessageFor('newContactName')}
            onChange={(e) => setNewContactName(e.target.value)}
            className="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="Name"
          />
        </div>
        <div className="flex flex-row items-center rounded-md pr-2  shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <select
            id="country"
            name="country"
            onChange={(e) => {
              setCountryCode(parseInt(e.target.value))
            }}
            value={countryCode}
            autoComplete="country"
            className=" m-0 rounded-md border-0 bg-transparent pr-7 text-gray-500 focus:outline-none text-sm"
          >
            {countryCodeOptions?.map((country, index) => {
              if (!country.isActive) return
              return (
                <option value={country.countryCode}>{country.shortName}</option>
              )
            })}
          </select>
          <input
            type="number"
            inputMode="numeric"
            pattern="\d*"
            name="contactPhone"
            id="contactPhone"
            value={newContactPhone}
            onBlur={() => simpleValidator.current.showMessageFor('newContactPhone')}
            onChange={(e) => setNewContactPhone(e.target.value.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', ''))}
            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="Phone"
          />
        </div>

        <button
          type="button"
          className="relative -ml-px whitespace-nowrap inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={handleSubmit}
        >
          Add
        </button>
      </div>
      <div className="mt-2 text-sm text-red-600">
        {simpleValidator.current.message('newContactPhone', newContactPhone, 'required|phone', { className: ' ' })}
      </div>
      <div className="mt-2 text-sm text-red-600">
        {simpleValidator.current.message('newContactName', newContactName, 'required', { className: ' ' })}
      </div>
    </div>
  );
}

export default CreateExpense;



