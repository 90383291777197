import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "../pages/auth/Login";
import Home from "../pages/Home";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import UserProfile from "../pages/user/UserProfile";
import ExpensePage from "../pages/expense/ExpensePage";
import EventPage from "../pages/expense/EventPage";
import Logout from "../pages/auth/Logout";
import CreateExpense from "../pages/expense/CreateExpense";
import CreateGroup from "../pages/expense/CreateGroup";
import VerifyCode from "../pages/auth/VerifyCode";
import Onboarding from "../pages/user/Onboarding";
import PaymentDetails from "../pages/user/PaymentDetails";
import User from "../pages/user/User";
import ListExpenses from "../pages/expense/ListExpenses";


const RouteApp: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<PublicRoute component={Login} />} />
      <Route path="/verifyCode" element={<PublicRoute component={VerifyCode} />} />
      <Route path="/logout" element={<PrivateRoute component={Logout} />} />
      <Route path="/onboarding" element={<PrivateRoute component={Onboarding} />} />
      <Route path="/userPaymentDetails" element={<PrivateRoute component={PaymentDetails} />} />
      <Route path="/createExpense" element={<PrivateRoute component={CreateExpense} />} />
      <Route path="/createGroup" element={<PrivateRoute component={CreateGroup} />} />
      <Route path="/userProfile" element={<PrivateRoute component={UserProfile} />} />
      <Route path="/expense/all" element={<PrivateRoute component={ListExpenses} />} />
      <Route path="/e/:shortCode" element={<PrivateRoute component={ExpensePage} />} />
      <Route path="/g/:shortCode" element={<PrivateRoute component={EventPage} />} />
      <Route path="/pay/:shortCode" element={<PrivateRoute component={ExpensePage} />} />
      <Route path="/u/:userPhone" element={<PrivateRoute component={User} />} />
    </Routes>
  );
}

export default RouteApp
