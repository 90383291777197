import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { User } from "./User.type";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import Header from "../Header";
import { UserContext } from "../../Context";
import SimpleReactValidator from 'simple-react-validator';

const MUTATION = gql`
mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      id
      countryCode
      name
      phone
      onBoardingStatus
    }
    success
    code
    message
  }
}
`;

const Onboarding: React.FC = () => {
  // const { userId } = useParams();
  // const { data } = useQuery(QUERY, {
  //   variables: {
  //     id: Number(userId)
  //   },
  // });
  // if (!data?.user) return null;
  const navigate = useNavigate();
  const context = React.useContext(UserContext)
  const user: User = context.user;

  const [countryCode, setCountryCode] = React.useState(user.countryCode);
  const [name, setName] = React.useState(user.name);
  const [phone, setPhone] = React.useState(user.phone);

  const [message, setMessage] = React.useState("");
  const [updateUser] = useMutation(MUTATION);
  const simpleValidator = useRef(new SimpleReactValidator())
  const onBoardingStatus = {
    fullName: true
  }
  const updateUserF = async (onBoardingStatus: any) => {
    // if (!simpleValidator.current.allValid()) {
    //   simpleValidator.current.showMessages();
    //   return;
    // }
    let dataEvent;

    try {
      dataEvent = await updateUser({
        variables: {
          input: {
            // id: user.id,
            name,
            onBoardingStatus
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }

    if (dataEvent?.data?.updateUser) {
      context.updateUser(dataEvent?.data?.updateUser.user)
      setMessage("");
      navigate("/userPaymentDetails", { replace: true });

    }
  };

  return (

    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        {message && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">

              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{message}</h3>

              </div>
            </div>
          </div>
        )}

        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Your Name?
        </h2>
      </div>


      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label htmlFor="code" className="block text-sm font-medium leading-6 text-gray-900">
              Enter your name
            </label>
            <div className="mt-2">
              <input
                id="name"
                name="name"
                type="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="name"
                required
                className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div className="mt-2 text-sm text-red-600">
                {simpleValidator.current.message('name', name, 'required', { className: ' ' })}
              </div>
            </div>
          </div>
          <div className="flex flex-row space-x-2">
            <button
              type="button"
              onClick={() => {
                let status = {
                  fullName: false
                }
                updateUserF(status)

              }}
              className="flex w-full color-indigo-600 justify-center rounded-md bg-white-600 px-3 py-1.5 text-sm font-semibold border border-indigo-600 leading-6  text-indigo-600 shadow-sm"
            >
              Skip
            </button>
            <button
              type="button"
              onClick={() => {
                let status = {
                  fullName: true
                }
                updateUserF(status)
              }}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Next
            </button>
          </div>
        </div>

      </div>
    </div>


  );
};

export default Onboarding;
