import { Fragment, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'


interface Props {
    name: string | undefined;
    amount: number | undefined;
    onConfirmation: () => Promise<void>;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>
}

const SendReminderConfirmation: React.FC<Props> = ({ name, amount, onConfirmation, onCancel }) => {
    const [show, setShow] = useState(true)

    return (
        <Transition.Root show={show} as={Fragment} appear >
            <Dialog as="div" className="fixed inset-0 flex items-center justify-center z-10" onClose={setShow}
                onClick={() => onCancel(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                </Transition.Child>
                <div className="relative z-10 overflow-y-auto rounded-xl m-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white p-6 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                            <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                {`A reminder will be sent to ${name} for the amount owed $${Math.abs(amount ? amount : 0)}.`}
                            </label>
                            <div className='flex flex-none flex-row mt-6 space-x-4'>
                                <button
                                    type="button"
                                    onClick={() => {
                                        onConfirmation()
                                        onCancel(false)
                                    }}
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Okay
                                </button>
                                <button
                                    type="button"
                                    onClick={() => { setShow(false); onCancel(false) }}
                                    className="flex w-full justify-center rounded-md border border-slate-400 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Cancel
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default SendReminderConfirmation;
