import { Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client"
import { ChevronDownIcon, EllipsisVerticalIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Expense, Transaction } from '../expense/Expense.type';
import { User } from '../user/User.type';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../Context';
import Search from '../Search';
import { isElementAccessExpression } from 'typescript';
import { GraphQLError } from 'graphql';

import SettleTransactions from './SettleTransactions';
import Events from '../Events';
  
export const QUERY = gql`
query Expenses($filterBy: ExpenseFilterInput) {
  expenses(filterBy: $filterBy) {
    name
    totalAmount
    shortCode
    transactions {
        id
      toUser {
        id
        name
        phone
      }
      amountOwed
      fromUser {
        id
        name
        phone
      }
      expense {
        id
        shortCode
        name
        totalAmount
      }
      amountPaid
      paidAt
    }
    user {
      name
      phone
    }
    userId
  }
}
`


export default function Expenses() {
    const { loading, data, refetch } = useQuery(QUERY, { variables: { filterBy: { type: "ALL" } } });
    const navigate = useNavigate();
    const context = useContext(UserContext);
    const user : User = context.user;
    const [expenses, setExpenses] = useState<Expense[]>([]);
    const [showSettleDialog, setShowSettleDialog] = useState(false);
    const [transactionToSettle, setTransactionToSettle] = useState<Transaction>();

    const [message, setMessage] = useState("");

    useEffect(() => {
        setExpenses(data?.expenses);
    }, [data]);
    


    const createExpense = async () => {
        navigate(`/createExpense`);  
    };

    
    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }



    function myTransaction(e: Expense) : Transaction|undefined {
        return e.transactions?.filter((t) => ((t.fromUser?.phone === user.phone || t.toUser?.phone === user.phone) && (t.paidAt==null)))[0];
    }

    function callbackSettleDialog(message: string) {
        if(message === 'success'){
          setMessage("Settlement Successful");
          refetch();
        }
        setShowSettleDialog(false);
      }

return (
    <div className="block">
        <div className="ml-2 mr-2 grid grid-cols-1 gap-x-6 gap-y-2 lg:grid-cols-3 xl:gap-x-8">
            <div className="overflow-hidden rounded-xl border border-gray-200">
                {!loading && expenses?.length === 0 && (
                    <div className="text-center">
                        <h3 className="mt-12 text-sm font-semibold text-gray-900">No expenses</h3>
                        <p className="mt-1 text-sm text-gray-500">Get started by creating a new expense.</p>
                        <div className="mt-6">
                        <button
                            type="button"
                            onClick={createExpense}
                            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                            New expense
                        </button>
                        </div>
                    </div>
                )}
                {showSettleDialog && (
                    <SettleTransactions settlement={{transaction:transactionToSettle, fromUser:user, toUser:transactionToSettle?.toUser}} notify={callbackSettleDialog} />
                )}
                {!loading && expenses?.length > 0 && (
                    <ul role="list" className="divide-y divide-gray-100">
                        {expenses?.filter((a) => (myTransaction(a)?.amountOwed||0>0)).map((e) => (
                            <li key={e.id} className="flex items-center justify-between gap-x-6 py-5">
                                <div className="min-w-0">
                                    <div className="flex items-start gap-x-3">
                                        <p onClick={() => {navigate('/e/'+e.shortCode)}} className="ml-4 text-sm font-normal leading-6 text-gray-900">{e.name} {(myTransaction(e)?.fromUser?.id===user.id)?'(Total: '+e.totalAmount+')':''} </p>
                                    </div>
                                </div>
                                <div className="flex flex-none items-center gap-x-4">  
                                    <span className={classNames(
                                        (myTransaction(e)?.fromUser?.id===user.id)? 'text-red-600' : 'text-green-600',
                                        'text-sm'
                                    )}> 
                                    {(myTransaction(e)?.fromUser?.id===user.id)?'-'+myTransaction(e)?.amountOwed||0:e.totalAmount}    
                                    </span>
                                    <Menu as="div" className="relative flex-none">
                                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                        <span className="sr-only">Open options</span>
                                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {(myTransaction(e)?.fromUser?.id===user.id) && (
                                            <Menu.Item>
                                                {({ active }) => (
                                                <button
                                                    onClick={() => { setTransactionToSettle(myTransaction(e));setShowSettleDialog(true); } }
                                                    className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}
                                                >
                                                    Mark As Settled
                                                </button>
                                                )}
                                            </Menu.Item>
                                        )}
                                        <Menu.Item>
                                            {({ active }) => (
                                            <button
                                                onClick={() => {navigate('/e/'+e.shortCode)}}
                                                className={classNames(
                                                active ? 'bg-gray-50' : '',
                                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                )}
                                            >
                                                Details
                                            </button>
                                            )}
                                        </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                    </Menu>
                                </div>
                            </li>
                        ))}
                    </ul>
                
                )}
            </div>
        </div>
    </div>
)
}