import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'

const items = [
  { name: 'New Expense', href: '/createExpense' },
  { name: 'New Event', href: '/createGroup' },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function ExpenseButton() {
  const navigate = useNavigate();

  const createExpense = async () => {
    navigate(`/createExpense`);  
  };

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        type="button"
        onClick={createExpense}
        className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
      >
        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        New Expense
      </button>
    </div>
  )
}
