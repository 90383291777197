import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Expense, Transaction } from "../expense/Expense.type";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import Header from "../Header";
import { Fragment } from 'react'
import { ChevronDownIcon, ChevronLeftIcon, EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/20/solid'
import { User } from "./User.type";
import { ExpenseUser } from "../expense/Expense.type";
import { UserContext } from "../../Context";
import { GraphQLError } from "graphql";

import SettleTransactions from "../transactions/SettleTransactions";
import { Menu, Transition } from "@headlessui/react";
import Loading from "../Loading";
import Breadcrumbs from "../Breadcrumbs";
import NotificationR from "../NotificationR";
import PaymentInfo from "../PaymentInfo";
import SendReminderConfirmation from "../transactions/ReminderConfirmation";
import Me from "../../Me";


export const QUERY = gql`
  query Transactions($filterBy: TransactionFilterInput) {
    transactions(filterBy: $filterBy) {
      amountPayable
      amountReceivable
      netAmountOwed
      transactions {
        id
        amountOwed
        amountPaid
        expense {
          id
          name
          shortCode
          totalAmount
        }
        fromUser {
          countryCode
          id
          name
          phone
        }
        toUser {
          countryCode
          id
          name
          phone
        }
      }
  }
}
`

const MUTATION = gql`
  mutation SendReminderForUnsettledTransactions($input: SendReminderForUnsettledTransactionsInput!) {
    sendReminderForUnsettledTransactions(input: $input) {
      code
      message
      success
      notifications
    }
  }
`;

const MUTATIONAMOUNTOWED = gql`
mutation SendReminderForNetAmountOwed($input: SendReminderForNetAmountOwedInput!){
  sendReminderForNetAmountOwed(input: $input){
    code
    message
    notification
    success
  }
}
`

interface UserTransactions {
  user: User,
  transactions: (Transaction | undefined)[],
}


const UserProfile: React.FC = () => {
  const navigate = useNavigate();

  const context = useContext(UserContext);
  const user: User = context.user;
  const [transactions, setTransactions] = useState<(Transaction | undefined)[]>([]);
  const [groupedTransactions, setGroupedTransactions] = useState<UserTransactions[]>([]);
  const [netAmountOwed, setNetAmountOwed] = useState<number | undefined>(0);
  const [message, setMessage] = React.useState("");
  const [showSettleDialog, setShowSettleDialog] = React.useState(false);
  const [transactionToSettle, setTransactionToSettle] = useState<Transaction>();

  const [showPaymentInfoDialog, setShowPaymentInfoDialog] = React.useState(false);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(true)
  const [isRefetch, setIsRefetch] = useState<boolean>(false)


  const [SendReminderForUnsettledTransactions] = useMutation(MUTATION);
  const [SendReminderForNetAmountOwed] = useMutation(MUTATIONAMOUNTOWED);




  const [groupBy, setgroupBy] = useState('Unsettled');
  const [groupByOptions, setGroupByOptions] = useState([
    { name: 'Unsettled', href: '#', current: true },
    { name: 'Settled', href: '#', current: false },
  ]);

  const { userPhone } = useParams();
  const userProfile: User | undefined = user.contacts?.find((c) => (c.phone == userPhone));

  const breadcrumb = {
    name: userProfile?.name || '',
    href: `/u/${userPhone}`,
    current: true,
    type: 'User',
  };

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      filterBy: {
        "transactionType": "ALL",
        "settlementType": "UNSETTLED",
        "filterByUserId": userProfile?.id
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsRefetch(true);
      await refetch();
    };

    fetchData();
  }, [refetch]);


  useEffect(() => {
    setTransactions(data?.transactions?.transactions);
    setNetAmountOwed(data?.transactions?.netAmountOwed);
    groupTransactionsByUser(data?.transactions?.transactions);
    if (context.breadcrumbs.some((b) => (b.name === breadcrumb.name))) return;
    context.updateBreadcrumbs([...context.breadcrumbs, breadcrumb].slice(-2));
  }, [data]);


  const sendReminderF = async (t: Transaction | undefined) => {
    let dataExpense;

    try {
      dataExpense = await SendReminderForUnsettledTransactions({
        variables: {
          input: {
            ids: [t?.id],
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }

    if (dataExpense?.data?.sendReminderForUnsettledTransactions?.success) {
      setMessage("Notification sent");
    }
  };

  const sendReminderNetAmountOwedF = async () => {
    let dataReminder;

    try {
      dataReminder = await SendReminderForNetAmountOwed({
        variables: {
          input: {
            fromUserId: Number(userProfile?.id),
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) => {
        setMessage(graphQLError.message)
        setIsSuccess(false)
      }
      );
    }
    if (dataReminder?.data?.sendReminderForNetAmountOwed?.success) {
      setIsSuccess(true)
      setMessage(dataReminder?.data.sendReminderForNetAmountOwed.message);
    } else {
      setIsSuccess(false)
      setMessage(dataReminder?.data.sendReminderForNetAmountOwed.message);

    }
  };



  function groupTransactionsByUser(transactions: Transaction[]) {
    const groupedTransactions: UserTransactions[] = [];
    if (!transactions) return;
    transactions.forEach((transaction) => {
      let userTransactions = groupedTransactions.find((ut) => (ut.user.id === transaction.toUser?.id));
      if (userTransactions) {
        userTransactions.transactions.push(transaction);
      } else {
        userTransactions = {
          user: transaction.toUser as User,
          transactions: [transaction],
        };
        groupedTransactions.push(userTransactions);
      }
    }
    );
    setGroupedTransactions(groupedTransactions);
  }

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }


  function handleGroupByChange(name: string) {
    setgroupBy(name);
    setGroupByOptions(groupByOptions.map((option, index) => (
      {
        ...option,
        current: option.name === name,
      }
    )));
    if (name === 'Settled') {
      setGroupedTransactions([]);
      refetch({
        filterBy: {
          "transactionType": "ALL",
          "settlementType": "SETTLED",
          "filterByUserId": userProfile?.id
        }
      });
    }
    if (name === 'Unsettled') {
      setGroupedTransactions([]);
      refetch({
        filterBy: {
          "transactionType": "ALL",
          "settlementType": "UNSETTLED",
          "filterByUserId": userProfile?.id
        }
      });
    }
  }

  const createExpense = async () => {
    navigate(`/createExpense`);
  };

  function getExpenseUser(u: User | undefined): ExpenseUser {
    return {
      id: u?.id,
      name: u?.name || '',
      phone: u?.phone || '',
      countryCode: u?.countryCode || 1,
    };
  }

  function callbackSettleDialog(message: string) {
    if (message === 'success') {
      setMessage("Settlement Successful");
      refetch();
    }
    setShowSettleDialog(false);
  }

  if (loading) return <Loading />

  return (
    <div className="min-h-full">

      <Header />
      <main>

        {isRefetch && <Me isRefetch={isRefetch} setIsRefetch={setIsRefetch} />}

        {message && (
          <NotificationR message={message} setMessage={setMessage} isSuccess={!isSuccess} />
        )}

        {showConfirmationDialog && (
          <SendReminderConfirmation name={userProfile?.name} amount={netAmountOwed} onConfirmation={sendReminderNetAmountOwedF} onCancel={setShowConfirmationDialog} />
        )}


        <div className="border-b border-gray-200 px-4 py-4 min-w-0">
          <Breadcrumbs />
        </div>
        <div className="mx-auto max-w-4xl py-4 sm:px-6 lg:px-8">
          <div className="flex justify-between border-b border-gray-200 pb-4 ">
            <h2 className="text-xl font-bold tracking-tight text-gray-900 px-4 ">
              {userProfile?.name}
            </h2>
            <div className="flex items-center justify-between px-4 min-w-0">
              <div className="flex items-center">
                <span className="font-medium text-gray-900 text-sm pr-3">Filter: </span>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-normal text-gray-700 hover:text-gray-900">
                      {groupBy}
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {groupByOptions.map((option, index) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <div
                                onClick={() => handleGroupByChange(option.name)}
                                className={classNames(
                                  option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                {option.name}
                              </div>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          {groupedTransactions && groupedTransactions.length == 0 && groupBy == 'Unsettled' && (
            <div className="text-center">
              <h3 className="mt-12 text-normal font-semibold text-green-700">You and {userProfile?.name} </h3>
              <h3 className="mt-1 text-normal font-semibold text-green-700">have nothing to settle 👏 🥳</h3>
            </div>
          )}

          {groupedTransactions && groupedTransactions.length == 0 && groupBy == 'Settled' && (
            <div className="text-center">
              <h3 className="mt-12 text-normal font-semibold text-red-700">You and {userProfile?.name} </h3>
              <h3 className="mt-1 text-normal font-semibold text-red-700">have no settled transactions</h3>
            </div>
          )}

          {showSettleDialog && (
            <SettleTransactions settlement={{ transaction: transactionToSettle, amount: netAmountOwed, fromUser: user, toUser: getExpenseUser(userProfile) }} notify={callbackSettleDialog} />
          )}

          <div className="inline-block min-w-full px-4 overflow-x-hidden">
            <table className="min-w-full">
              {groupedTransactions && groupedTransactions.map((t, index) => (
                <tbody key={t.user.id}>
                  <tr className="text-sm leading-6 text-gray-900 ">
                    <th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold text-left">
                      Paid By {t.user.name}
                      <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                      <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                    </th>
                  </tr>
                  {t.transactions?.length > 0 && (
                    <tr>
                      <td>
                        <div className="inline-block min-w-full ">
                          <table className="min-w-full text-left">
                            <tbody>
                              {t.transactions && t.transactions.map((transaction, index) => (
                                <tr key={transaction?.id}>
                                  <td className="py-5 pr-6 sm:table-cell">
                                    <div className="text-sm leading-6 text-gray-900" onClick={() => { navigate('/e/' + transaction?.expense?.shortCode) }}>{transaction?.expense?.name}</div>
                                  </td>
                                  <td className="relative py-5 text-right">
                                    <div className="flex justify-end">
                                      <div className="flex items-start gap-x-3">
                                        <div className="text-sm font-medium leading-6 text-gray-900 pr-3">

                                          {t.user.id === user.id && (
                                            <span className='text-green-600'>{transaction?.amountOwed}</span>
                                          )}
                                          {t.user.id != user.id && (
                                            <span className='text-red-600'>-{transaction?.amountOwed}</span>
                                          )}

                                        </div>
                                      </div>
                                      <div className="flex flex-none items-center gap-x-4">
                                        <Menu as="div" className="relative flex-none">
                                          <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                            <span className="sr-only">Open options</span>
                                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                          </Menu.Button>
                                          <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                          >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                              {groupBy === 'Unsettled' && (
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      onClick={() => { setTransactionToSettle(transaction); setShowSettleDialog(true); }}
                                                      className={classNames(
                                                        active ? 'bg-gray-50' : '',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                      )}
                                                    >
                                                      Mark As Settled<span className="sr-only">, {transaction?.expense?.name}</span>
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              )}
                                              {groupBy === 'Unsettled' && (t.user.id === user.id) && (
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      onClick={() => { sendReminderF(transaction) }}
                                                      className={classNames(
                                                        active ? 'bg-gray-50' : '',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                      )}
                                                    >
                                                      Send Reminder
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              )}
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    onClick={() => { navigate('/e/' + transaction?.expense?.shortCode) }}
                                                    className={classNames(
                                                      active ? 'bg-gray-50' : '',
                                                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}
                                                  >
                                                    Details<span className="sr-only">, {transaction?.expense?.name}</span>
                                                  </button>
                                                )}
                                              </Menu.Item>
                                            </Menu.Items>
                                          </Transition>
                                        </Menu>
                                      </div>
                                    </div>
                                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                  </td>
                                </tr>
                              ))}

                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ))}
              {groupBy === 'Unsettled' && groupedTransactions.length != 0 && (
                <tfoot>
                  <tr className="mt-8 mb-12">
                    <td>
                      <div className="flex items-end">
                        <div className="flex-auto text-left text-md font-semibold text-gray-900">
                          Total:
                        </div>
                        <div className="text-md font-semibold text-gray-900 pr-3">{netAmountOwed}</div>
                      </div>

                      <span
                        onClick={() => setShowPaymentInfoDialog(true)}
                        className="text-sm font-semibold underline text-indigo-600 cursor-pointer"
                      >See Payment Details</span>

                      <div className="mt-8 flex flex-row gap-x-2">
                        <button
                          type="button"
                          onClick={() => { setShowSettleDialog(true) }}
                          // onClick={() => { setSettlement({...settlement, transaction: undefined}); toggleSettledDialog();}}
                          className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Settle Net Amount
                        </button>

                        {(netAmountOwed !== undefined && netAmountOwed > 0) &&
                          <button
                            type="button"
                            onClick={() => { setShowConfirmationDialog(true) }}
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Send Reminder
                          </button>
                        }
                      </div>

                    </td>
                  </tr>

                </tfoot>
              )}

              {showPaymentInfoDialog && <PaymentInfo userProfile={userProfile} setShowPaymentInfoDialog={setShowPaymentInfoDialog} />}
            </table>
          </div>
        </div>

      </main>
    </div>
  );
};

export default UserProfile;
