import React, { useEffect, useRef } from "react";
import { UserContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import { ExpenseUser, Transaction } from "./Expense.type";
import { gql, useMutation, ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import Select from "react-tailwindcss-select";
import Header from "../Header";
import { Connection, User } from "../user/User.type";
import SimpleReactValidator from 'simple-react-validator';

interface UserOption {
  label: string;
  value: string;
  disabled?: boolean;
}

const userOptionClass: UserOption = {
  label: "",
  value: "",
  disabled: false,
};

const MUTATION = gql`
mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    code
    message
    success
    event {
      id
      shortCode
      expenses {
        name
        totalAmount
        shortCode
      }
    }
  }
}
`;

const CreateGroup: React.FC = () => {
  const navigate = useNavigate();
  const context = React.useContext(UserContext);
  const user: User = context.user;

  const [message, setMessage] = React.useState("");
  const [eventName, setEventName] = React.useState("");

  const [showCreatePayable, setShowCreatePayable] = React.useState(false);
  const [showCreatePayee, setShowCreatePayee] = React.useState(false);

  const [participant, setParticipant] = React.useState<UserOption[]>([]);
  const [payable, setPayable] = React.useState<UserOption[]>([]);

  const [transactions, setTransactions] = React.useState<Transaction[]>([]);

  const [options, setOptions] = React.useState<UserOption[]>([]);
  const simpleValidator = useRef(new SimpleReactValidator())

  useEffect(() => {
    let o = context.user.connections?.map((connection: Connection) => {
      return { label: connection.contact.name || "", value: connection.contact.phone || "" }
    });
    if (!(o?.some((option) => option.value === context.user.phone))) {
      o?.unshift({ label: context.user.name || "", value: context.user.phone || "" })
    }
    setParticipant([{ label: user.name as string, value: user.phone, disabled: false }]);

    setOptions(o || []);
  }, []);




  const [createEvent] = useMutation(MUTATION);
  const forceUpdate = useForceUpdate();

  function useForceUpdate() {
    const [value, setValue] = React.useState(0);
    return () => setValue(value => value + 1);
  }
  const createExpenseF = async () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate();
      return;
    }
    let dataEvent;

    try {
      dataEvent = await createEvent({
        variables: {
          input: {
            name: eventName,
            users: eventUsers(),
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }

    if (dataEvent?.data?.createEvent) {
      setMessage("");
      // navigate("/g/" + dataEvent.data.createEvent.event.shortCode);
      navigate("/");

    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      createExpenseF();
    }
  };

  const onCreatePayee = (contact: ExpenseUser) => {
    if (!options.some((option) => option.value === contact.phone)) {
      setOptions([...options, { label: contact.name, value: contact.phone }]);
    }
    setParticipant([...participant, { label: contact.name, value: contact.phone }]);
    setShowCreatePayee(false);
  }

  const handlePayeeChange = (payee: any) => {
    setParticipant(payee);
  };

  const handlePayableChange = (payable: any) => {
    setPayable(payable);
  }


  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  }

  const eventUsers = () => {
    let users: { id?: string, phone?: string, countryCode?: number }[] = [];
    participant.forEach((p) => {
      let user = context.user.connections?.find((c) => c.contact.phone === p.value)?.contact;
      console.log(user);
      if (user) {
        users.push({ id: user.id });
      }
      else {
        users.push({ phone: p.value, countryCode: 1 });
      }
    });
    return users;

  }



  React.useEffect(() => {
    if (!context.user.id) {
      navigate("/login");
    }
  }, [context]);

  return (
    <div className="min-h-full">
      <Header />
      <form>
        <div className="space-y-12 px-4 mt-2">
          <div className="pb-12">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 py-4">New Expense Group</h1>

            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="expense-name"
                    id="expense-name"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    onBlur={() => simpleValidator.current.showMessageFor('name')}
                    className="block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="mt-2 text-sm text-red-600">
                  {simpleValidator.current.message('name', eventName, 'required', { className: ' ' })}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                  Participants
                </label>
                <div className="mt-2 flex flex-grow items-stretch focus-within:z-10 gap-x-1.5 ">
                  <Select
                    primaryColor={"indigo"}
                    value={participant}
                    onChange={handlePayeeChange}
                    isMultiple={true}
                    placeholder="Select..."
                    options={options}
                    isSearchable={true}
                  />
                  <button
                    type="button"
                    className="relative -ml-px whitespace-nowrap inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setShowCreatePayee(true)}
                  >
                    + New
                  </button>
                </div>
                {showCreatePayee && <CreateContact onCreateContact={onCreatePayee} />}

              </div>
            </div>

            <div className="mt-4">
              <button
                type="button"
                onClick={createExpenseF}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Submit
              </button>
            </div>
            <div className="mt-2">
              <button
                type="button"
                onClick={() => { navigate("/"); }}
                className="flex w-full justify-center rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};



function CreateContact(props: { onCreateContact: (contact: ExpenseUser) => void }) {

  const [newContactName, setNewContactName] = React.useState("");
  const [newContactPhone, setNewContactPhone] = React.useState("");

  const [countryCode, setCountryCode] = React.useState(1);

  const context = React.useContext(UserContext);
  const countryCodeOptions = context.countryCodes;

  const simpleValidator = useRef(new SimpleReactValidator())
  const forceUpdate = useForceUpdate();

  function useForceUpdate() {
    const [value, setValue] = React.useState(0);
    return () => setValue(value => value + 1);
  }
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate();
      return;
    }

    props.onCreateContact({
      name: newContactName,
      phone: newContactPhone,
      countryCode: countryCode,
    });
    newContactName && setNewContactName("");
    newContactPhone && setNewContactPhone("");
  }


  return (
    <div>

      <div className="mt-2 flex flex-grow items-stretch focus-within:z-10 gap-x-1.5 ">
        <div className="rounded-md px-3 pb-1.5 pt-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <input
            type="text"
            name="contactName"
            id="contactName"
            value={newContactName}
            onBlur={() => simpleValidator.current.showMessageFor('newContactName')}
            onChange={(e) => setNewContactName(e.target.value)}
            className="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="Name"
          />
        </div>
        <div className="flex flex-row items-center rounded-md pr-2  shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <select
            id="country"
            name="country"
            onChange={(e) => {
              setCountryCode(parseInt(e.target.value))
            }}
            value={countryCode}
            autoComplete="country"
            className=" m-0 rounded-md border-0 bg-transparent pr-7 text-gray-500 focus:outline-none text-sm"
          >
            {countryCodeOptions?.map((country, index) => {
              if (!country.isActive) return
              return (
                <option value={country.countryCode}>{country.shortName}</option>
              )
            })}
          </select>
          <input
            type="number"
            pattern="\d*"
            name="contactPhone"
            id="contactPhone"
            value={newContactPhone}
            onBlur={() => simpleValidator.current.showMessageFor('newContactPhone')}
            onChange={(e) => setNewContactPhone(e.target.value.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', ''))}
            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="Phone"
          />
        </div>

        <button
          type="button"
          className="relative -ml-px whitespace-nowrap inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={handleSubmit}
        >
          Add
        </button>
      </div>
      <div className="mt-2 text-sm text-red-600">
        {simpleValidator.current.message('newContactPhone', newContactPhone, 'required|phone', { className: ' ' })}
      </div>
      <div className="mt-2 text-sm text-red-600">
        {simpleValidator.current.message('newContactName', newContactName, 'required', { className: ' ' })}
      </div>
    </div>
  );
}

export default CreateGroup;



