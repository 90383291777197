import React from "react";
import { User, userClass } from "./pages/user/User.type";

const PaymentMethods = [
  {
    id: 1,
    label: "PayPal",
    isChecked: false,
    image: "https://w7.pngwing.com/pngs/632/1015/png-transparent-paypal-logo-computer-icons-payment-paypal-blue-angle-service-thumbnail.png"
  },
  {
    id: 2,
    label: "Venmo",
    isChecked: false,
    image: "https://upload.wikimedia.org/wikipedia/commons/8/84/Venmo_logo.png"
  },
  {
    id: 3,
    label: "Zelle",
    isChecked: false,
    image: "https://freelogopng.com/images/all_img/1659810741zelle-app-logo.png"
  },
  {
    id: 4,
    label: "Cash App",
    isChecked: false,
    image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Square_Cash_app_logo.svg/1200px-Square_Cash_app_logo.svg.png"
  },
  {
    id: 5,
    label: "Apple Pay",
    isChecked: false,
    image: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
  },
  {
    id: 6,
    label: "Google Pay",
    isChecked: false,
    image: "https://play-lh.googleusercontent.com/aFWiT2lTa9CYBpyPjfgfNHd0r5puwKRGj2rHpdPTNrz2N9LXgN_MbLjePd1OTc0E8Rl1"
  },
  {
    id: 7,
    label: "Other",
    isChecked: false,
    image: "https://cdn-icons-png.freepik.com/512/8749/8749431.png"
  }
]

export interface UserContextData {
  user: User;
  updateUser: (user: User) => void;
  config: { groupBy: string };
  countryCodes: CountryCode[];
  updateCountryCodes: (country: CountryCode[]) => void;
  updateConfig: (config: { groupBy: string }) => void;
  afterLogin: string;
  breadcrumbs: Breadcrumb[];
  updateBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  paymentMethod: PaymentMethod[];
}

export const userContextDefaultValue: UserContextData = {
  user: userClass,
  updateUser: () => null,
  config: { groupBy: 'Users' },
  countryCodes: [],
  updateCountryCodes: () => null,
  updateConfig: () => null,
  afterLogin: '/',
  breadcrumbs: [],
  updateBreadcrumbs: () => null,
  paymentMethod: PaymentMethods,
};

export const UserContext = React.createContext<UserContextData>(
  userContextDefaultValue
);

export interface Breadcrumb {
  name: string;
  href: string;
  current: boolean;
  type: string;
}
export interface CountryCode {
  id: number
  name: string
  shortName: string
  countryCode: number
  isActive: boolean
}
export interface PaymentMethod {
  id: number;
  label: string;
  isChecked: boolean;
  image: string;
}



