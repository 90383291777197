import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Expense, ExpenseUser, Transaction } from "./Expense.type";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import Header from "../Header";
import { Fragment } from 'react'
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { Menu, Transition } from "@headlessui/react";
import { User } from "../user/User.type";
import { UserContext } from "../../Context";
import Breadcrumbs from "../Breadcrumbs";
import { GraphQLError } from "graphql/error";
import NotificationR from "../NotificationR";
import SettleTransactions from "../transactions/SettleTransactions";

export const QUERY = gql`
    query Expense($shortCode: String) {
    expense(shortCode: $shortCode) {
        id
        name
        shortCode
        totalAmount
        transactions {
          id
          amountOwed
          amountPaid
          paidAt
          expense {
              id
              name
          }
          fromUser {
              id
              name
              phone
              countryCode
          }
          toUser {
              id
              countryCode
              name
              phone
          }
        }
    }
    }
`;

const MUTATION = gql`
  mutation SendReminderForAllUnsettledTransactions($input: SendReminderForAllUnsettledTransactionsInput!) {
    sendReminderForAllUnsettledTransactions(input: $input) {
      code
      message
      success
      notifications
    }
  }
`;

const SINGLEMUTATION = gql`
  mutation SendReminderForUnsettledTransactions($input: SendReminderForUnsettledTransactionsInput!) {
    sendReminderForUnsettledTransactions(input: $input) {
      code
      message
      success
      notifications
    }
  }
`;

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const EventPage: React.FC = () => {
  const navigate = useNavigate();

  const { shortCode } = useParams();
  const context = useContext(UserContext);
  const user: User = context.user;
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      shortCode: String(shortCode)
    },
  });

  const [SendReminderForAllUnsettledTransactions] = useMutation(MUTATION);
  const [SendReminderForUnsettledTransactions] = useMutation(SINGLEMUTATION);
  const [message, setMessage] = React.useState("");

  const [showSettleDialog, setShowSettleDialog] = React.useState(false);

  useEffect(() => {
    if (!data?.expense) return;
    const breadcrumb = {
      name: data.expense?.name || '',
      href: `/e/${data.expense?.shortCode}`,
      current: true,
      type: 'Expense',
    };
    if (context.breadcrumbs.some((b) => (b.name === breadcrumb.name))) return;
    context.updateBreadcrumbs([...context.breadcrumbs, breadcrumb].slice(-2));
  }, [data]);


  if (!data?.expense) return null;
  const expense: Expense = data.expense;


  const sendReminderF = async () => {
    let dataExpense;

    try {
      dataExpense = await SendReminderForAllUnsettledTransactions({
        variables: {
          input: {
            expenseId: parseInt(expense.id),
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }

    if (dataExpense?.data?.sendReminderForAllUnsettledTransactions?.success) {
      setMessage("Notifications sent");
    }
  };


  const sendSingleReminderF = async (t: Transaction | undefined) => {
    let dataExpense;

    try {
      dataExpense = await SendReminderForUnsettledTransactions({
        variables: {
          input: {
            ids: [t?.id],
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }

    if (dataExpense?.data?.sendReminderForUnsettledTransactions?.success) {
      setMessage("Notification sent");
    }
  };

  function payables(expense: Expense): string | undefined {
    return expense?.transactions?.map((t) => (t.toUser?.name)).filter((v, i, a) => a.indexOf(v) === i).join(', ');
  }

  function overDueTransactions(expense: Expense): Transaction[] | undefined {
    return expense?.transactions?.filter((el) => {
      return !(el.fromUser?.phone === el.toUser?.phone)
    });
  }

  function myTransaction(expense: Expense): Transaction | undefined {
    return overDueTransactions(expense)?.find((el) => {
      return (el.fromUser?.phone === user.phone)
    });
  }

  function callbackSettleDialog(message: string) {
    if (message === 'success') {
      setMessage("Settlement Successful");
      refetch({
        shortCode: String(shortCode)
      })
    }
    setShowSettleDialog(false);
  }

  return (
    <div className="min-h-full">
      <Header />
      <main>
        {message && (
          <NotificationR message={message} setMessage={setMessage} />
        )}
        {showSettleDialog && (
          <SettleTransactions settlement={{ transaction: myTransaction(expense), fromUser: user, toUser: myTransaction(expense)?.toUser }} notify={callbackSettleDialog} />
        )}
        <div className="border-b border-gray-200 px-4 py-4 min-w-0">
          <Breadcrumbs />
        </div>
        <div className="mx-auto max-w-4xl py-6 sm:px-6 lg:px-8">
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="flex-1 overflow-x-hidden min-w-0">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900 px-4" >{expense.name} <span className="text-base font-normal">(Total: {expense.totalAmount})</span></h2>
              <div className="mt-6 overflow-hidden border-t border-gray-100">
                <div className="mx-auto max-w-4xl sm:px-6 lg:px-8">
                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <table className="w-full text-left">
                      <tbody>
                        <tr className="text-sm leading-6 text-gray-900">
                          <th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold text-left px-4">
                            Paid By {payables(expense)}
                            <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                            <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                          </th>
                        </tr>
                        {expense && expense.transactions && overDueTransactions(expense)?.map((transaction) => (
                          <tr key={transaction?.id}>
                            <td className="py-5 pr-6 sm:table-cell px-4">
                              <div className={transaction?.fromUser?.phone === user.phone ? 'font-semibold text-sm leading-6 text-gray-900' : 'text-sm leading-6 text-gray-900'} onClick={() => { navigate('/u/' + transaction?.fromUser?.phone) }}>
                                {transaction?.fromUser?.name}
                              </div>
                            </td>
                            <td className="relative py-5 text-right">
                              <div className="flex justify-end">
                                <div className="flex items-start gap-x-3">
                                  {transaction?.amountOwed != transaction?.amountPaid && (
                                    <span className={transaction?.fromUser?.phone === user.phone ? 'font-semibold text-red-600 text-sm' : 'text-red-600 text-sm'}>Unpaid</span>
                                  )}
                                  {transaction?.amountOwed === transaction?.amountPaid && (
                                    <span className={transaction?.fromUser?.phone === user.phone ? 'font-semibold text-green-600 text-sm' : 'text-green-600 text-sm'}>Paid</span>
                                  )}
                                  <div className={transaction?.fromUser?.phone === user.phone ? 'font-semibold text-sm leading-6 text-gray-900 pr-3' : 'text-sm leading-6 text-gray-900 pr-3'}>{transaction?.amountOwed}</div>
                                </div>
                                <div className="flex flex-none items-center gap-x-4">
                                  <Menu as="div" className="relative flex-none">
                                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                      <span className="sr-only">Open options</span>
                                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {payables(expense)?.includes(user.name || '') && (
                                          <Menu.Item>
                                            {({ active }) => (
                                              <button
                                                onClick={() => { sendSingleReminderF(transaction) }}
                                                className={classNames(
                                                  active ? 'bg-gray-50' : '',
                                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                )}
                                              >
                                                Send Reminder
                                              </button>
                                            )}
                                          </Menu.Item>
                                        )}


                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() => { navigate('/u/' + transaction?.fromUser?.phone) }}
                                              className={classNames(
                                                active ? 'bg-gray-50' : '',
                                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                                              )}
                                            >
                                              Details<span className="sr-only">, {transaction?.expense?.name}</span>
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>
                              </div>
                              <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                              <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                            </td>
                          </tr>
                        ))}

                      </tbody>
                      <tfoot>
                        <tr>
                          <td className="px-4">

                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    {payables(expense)?.includes(user.name || '') && (
                      <div className="px-4 py-4">
                        <button
                          type="button"
                          onClick={() => { sendReminderF() }}
                          className="flex w-full justify-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Send Reminders
                        </button>
                      </div>
                    )}
                    {!payables(expense)?.includes(user.name || '') && (myTransaction(expense)?.amountOwed != myTransaction(expense)?.amountPaid) && (
                      <div className="px-4 py-4">
                        <button
                          type="button"
                          onClick={() => { setShowSettleDialog(true) }}
                          className="flex w-full justify-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Settle Amount
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>
      </main>

    </div>
  );
};

export default EventPage;
