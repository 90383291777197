import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'
import { useContext, useEffect, useState } from 'react';
import { Breadcrumb, UserContext } from '../Context';
import { useNavigate } from 'react-router-dom';


export default function Breadcrumbs() {
    const context = useContext(UserContext);
    const navigate = useNavigate();

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <div onClick={() => navigate('/')} className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-4 w-4 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </div>
          </div>
        </li>
        {context.breadcrumbs.map((page) => (
          <li key={Math.random()}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <div
                onClick={() => navigate(page.href)}
                className="ml-2 text-xs font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </div>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}