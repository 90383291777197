import React, { useEffect, useRef } from "react";
import { Fragment, useState } from 'react'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import NotificationR from "./NotificationR";

type Props = {
    setShowPaymentInfoDialog: React.Dispatch<React.SetStateAction<boolean>>;
    userProfile: any
}

interface StandardPaymentMethod {
    username?: string;
    emailornumber: string;
    image?: string;
}

interface OtherPaymentMethod {
    value: string;
    name: string;
}

interface PaymentData {
    [key: string]: StandardPaymentMethod | OtherPaymentMethod[];
}


const PaymentMethods: React.FC<{ paymentData: PaymentData, setMessage: React.Dispatch<React.SetStateAction<string>> }> = ({ paymentData, setMessage }) => {

    const getMethodDisplayName = (method: string): string => {
        switch (method) {
            case "venmo":
                return "Venmo";
            case "zelle":
                return "Zelle";
            case "paypal":
                return "Paypal";
            case "cashapp":
                return "Cash App";
            case "applepay":
                return "Apple Pay";
            case "googlepay":
                return "Google Pay";
            default:
                return method;
        }
    };

    return (
        <div className="mt-2">
            {Object.entries(paymentData).map(([key, value], index) => (
                <div key={index}>
                    {Array.isArray(value) ? (
                        <div>
                            {value.map((item: OtherPaymentMethod, i: number) => (
                                <div key={i} className="flex items-center justify-between w-full mb-2 border-b-2 px-2">
                                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                        <div className="flex flex-row items-center">
                                            <img src="https://cdn-icons-png.freepik.com/512/8749/8749431.png" alt={`${key} logo`} className="mr-2 mr-0 h-4 w-4" />

                                            <strong className="mr-1">{item.name}:</strong>
                                            <span>{item.value}</span>
                                        </div>
                                    </label>

                                    <DocumentDuplicateIcon onClick={() => {
                                        navigator.clipboard.writeText(item.value)
                                            .then(() => {
                                                setMessage("Copied to clipboard!")
                                            })
                                            .catch(err => {
                                                console.error('Failed to copy: ', err);
                                            });
                                    }} className="h-4 w-4 text-gray-400" aria-hidden="true" />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="flex items-center justify-between w-full mb-2 border-b-2 px-2">
                            <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                <div className="flex flex-row items-center">
                                    {value.image && <img src={value.image} alt={`${key} logo`} className="mr-2 mr-0 h-4 w-4" />}
                                    <strong className="mr-1">{getMethodDisplayName(key)}:</strong>
                                    {value.username && <span>{value.username}</span>}
                                    {value.emailornumber && <span>{value.emailornumber}</span>}
                                </div>
                            </label>

                            <DocumentDuplicateIcon onClick={() => {
                                navigator.clipboard.writeText(value.username ?? value.emailornumber)
                                    .then(() => {
                                        setMessage("Copied to clipboard!")
                                    })
                                    .catch(err => {
                                        console.error('Failed to copy: ', err);
                                    });
                            }} className="h-4 w-4 text-gray-400" aria-hidden="true" />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};


const PaymentInfo: React.FC<Props> = ({ setShowPaymentInfoDialog, userProfile }) => {
    const [show, setShow] = useState(true)
    const [message, setMessage] = useState("");

    return (
        <>
            {message && (
                <NotificationR message={message} setMessage={setMessage} />
            )}
            <Transition.Root show={show} as={Fragment} appear >
                <Dialog as="div" className="fixed inset-0 flex items-center justify-center z-10" onClose={setShow} onClick={() => setShowPaymentInfoDialog(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                    </Transition.Child>
                    <div className="relative inset-0 z-10 w-full overflow-y-auto rounded-xl m-4 sm:p-6 md:p-20">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white p-6 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                                <div className="pt-2">
                                    <label htmlFor="location" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Payment Details
                                    </label>
                                    {Object.keys(userProfile.paymentMethods).length !== 0 ? (
                                        <PaymentMethods paymentData={userProfile.paymentMethods} setMessage={setMessage} />
                                    ) : (
                                        <div className="pt-2 flex-auto text-center text-sm font-normal text-gray-900">
                                            Payment methods are not added yet.
                                        </div>
                                    )}
                                </div>
                                <div className="mt-8">
                                    <button
                                        type="button"
                                        onClick={() => { setShowPaymentInfoDialog(false) }}
                                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default PaymentInfo;