import { Transaction } from "../expense/Expense.type";



function ListTransactions(props: { transactions: Transaction[] }) {
  
    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    }
    const statuses = {
      'Paid': 'text-green-700 bg-green-50 ring-green-600/20',
      'Unpaid': 'text-red-700 bg-red-50 ring-red-600/10',
    }
    function status(transaction: Transaction) : string {
      if (transaction.amountOwed - transaction.amountPaid === 0) {
        return 'Paid';
      }
      return 'Unpaid';
    }
    
    function classNames(...classes: string[]) {
      return classes.filter(Boolean).join(' ')
    }
  
    return (
        <div className="flow-root">
          <div className="inline-block min-w-full py-2 align-middle">
          {props.transactions.length>0 && 
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pr-3 text-left text-xs font-normal text-gray-700 sm:pl-6 lg:pl-8"
                  >
                    From
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pr-3 text-left text-xs font-normal text-gray-700 sm:pl-6 lg:pl-8"
                  >
                    To
                  </th>
                  <th scope="col" className="py-3.5 pr-3 text-left text-xs font-normal text-gray-700 sm:pl-6 lg:pl-8">
                    Amount
                  </th>
                  <th className="sr-only">Status</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {props.transactions.map((t) => (
                  <tr key={Math.random()}>
                    <td className="whitespace-nowrap py-4 pr-3 text-xs font-normal text-gray-900 sm:pl-6 lg:pl-8">
                      {t.fromName}
                    </td>
                    <td className="whitespace-nowrap py-4 pr-3 text-xs font-normal text-gray-900 sm:pl-6 lg:pl-8">
                      {t.toName}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pr-4 text-right text-xs font-normal sm:pr-6 lg:pr-8">
                      <div className="flex -space-x-px">
                        <input
                          type="number"
                          pattern="\d*"
                          name="amount-owed"
                          id="amount-owed"
                          value={t.amountOwed}
                          onChange={handleAmountChange}
                          className="relative block w-full rounded-none border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs sm:leading-6"
                        />
                      </div>
                    </td>
                    <td className="py-5 text-left">
                      <div className="flex ">
                          <div
                            className={classNames(
                              statuses[status(t) as keyof typeof statuses],
                              'rounded-md py-1 px-2 text-xs font-normal ring-1 ring-inset'
                            )}
                          >
                            {status(t)}
                          </div>
                      </div>
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
          </div>
        
        </div>
    );
  }

  export default ListTransactions;

