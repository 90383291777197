import React from "react";
import { UserContext } from "../../Context";

import { useNavigate } from "react-router-dom";
import { userClass } from "../user/User.type";

const Logout: React.FC = () => {
  const context = React.useContext(UserContext);
  const navigate = useNavigate();

  const logout = async () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("config");
    context.updateUser(userClass);
    navigate("/");
  };

  return (
    <>
      <button type="button" onClick={logout}>
        Logout
      </button>
    </>
  );
}

export default Logout
