import React from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Context";
import Header from "./Header";
import Expenses from "./transactions/ByExpenses";
import Users from "./transactions/ByUsers";
import { Fragment, useState } from 'react'
import { gql, useMutation, ApolloError } from "@apollo/client";
import { XCircleIcon, } from '@heroicons/react/24/outline'

import { GraphQLError } from "graphql";
import { User } from "./user/User.type";
import Me from "../Me";



const MUTATION = gql`
mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      id
      countryCode
      name
      phone
      paymentMethods
      onBoardingStatus
    }
    success
    code
    message
  }
}
`;


const Home: React.FC = () => {
  const navigate = useNavigate();
  const context = React.useContext(UserContext);
  const user: User = context.user;
  const [groupBy, setgroupBy] = useState(context.config.groupBy);
  const [groupByOptions, setGroupByOptions] = useState([
    { name: 'Users', href: '#', current: context.config.groupBy === 'Users' },
    { name: 'Expenses', href: '#', current: context.config.groupBy === 'Expenses' },
  ]);
  const [message, setMessage] = React.useState("");
  const [showReminder, setShowReminder] = useState(false);

  const [updateUser] = useMutation(MUTATION);


  const [paidBy, setpaidBy] = useState("All");
  const [paidByOptions, setPaidByOptions] = useState([
    { name: 'All', href: '#', current: paidBy === 'ALL' },
    { name: 'You', href: '#', current: paidBy === 'You' },
    { name: 'Others', href: '#', current: paidBy === 'Others' },
  ]);

  const getCurrentComponent = (): JSX.Element => {
    if (groupBy === 'Expenses') {
      return <Expenses />
    }
    return <Users />
  }

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  function handleGroupByChange(name: string) {
    setgroupBy(name);

    context.updateConfig({ groupBy: name });


    setGroupByOptions(groupByOptions.map((option) => (
      {
        ...option,
        current: option.name === name,
      }
    )));
  }


  React.useEffect(() => {
    if (!context.user.id) {
      navigate("/login");
    }

    if (context.breadcrumbs.length > 0) {
      context.updateBreadcrumbs([]);
    }
  }, [context]);



  React.useEffect(() => {

    // Determine if any value is false
    if (user.onBoardingStatus) {
      const hasIncompleteTasks = Object.values(user.onBoardingStatus).some(value => !value);
      setShowReminder(hasIncompleteTasks);
    }


  }, [user.onBoardingStatus]);

  // Function to map keys to user-friendly titles
  // const mapKeyToTitle: any = {
  //   fullName: 'Personal Info',
  //   paymentDetails: 'Payment Info',
  // };

  // Transform the data object into an array format
  // const reminderInfo = Object.keys(user.onBoardingStatus).map((key, index) => ({
  //   id: index + 1,
  //   title: mapKeyToTitle[key] || key,
  //   isDone: user.onBoardingStatus[key],
  // }));


  const reminderInfo = [
    {
      id: 1,
      title: "Personal Info",
      isDone: user.onBoardingStatus?.fullName
    }, {
      id: 2,
      title: "Payment Info",
      isDone: user.onBoardingStatus?.paymentDetails
    },
  ]

  const updateUserF = async (onBoardingStatus: any) => {

    let dataEvent;

    try {
      dataEvent = await updateUser({
        variables: {
          input: {
            onBoardingStatus
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }

    if (dataEvent?.data?.updateUser) {
      context.updateUser(dataEvent?.data?.updateUser.user)
      // setMessage("");
    }
  };

  return (
    <>
      <div className="min-h-full overflow-x-hidden">
        <Header />
        <Me />

        <main>
          <div className="mx-auto max-w-4xl sm:px-6 lg:px-8">
            <div>
              <div className="flex items-baseline justify-between border-b  border-gray-200 px-4 py-4 min-w-0">
                <h1 className="text-medium font-bold tracking-tight text-gray-900">Hi {context.user.name}</h1>
                <div className="flex items-center justify-between min-w-0">
                  <div className="flex items-center">
                    <span className="font-medium text-gray-900 text-sm pr-3">Net Amount: </span>
                    <div className={classNames(
                      (context.user.settlements?.netAmountOwed || 0) < 0 ? 'text-red-600 ' : 'text-green-700 ',
                      'inline-flex items-baseli ne rounded-full px-2.5 py-0.5 text-sm font-extrabold md:mt-2 lg:mt-0'
                    )}>
                      {(context.user.settlements?.netAmountOwed || 0)}
                    </div>
                  </div>
                </div>
              </div>

              {showReminder && <div className="relative ">
                <XCircleIcon
                  onClick={() => {
                    let status = {
                      fullName: true,
                      paymentDetails: true,
                    };
                    updateUserF(status);
                  }}
                  className="h-5 w-5 text-gray-400 absolute top-1 right-1"
                  aria-hidden="true"
                />

                <div className="shadow-[inset_0_-1px_4px_rgba(0,0,0,0.07)] bg-white py-0 border-b border-gray-200">
                  <ol
                    onClick={() => {
                      navigate("/userProfile");
                    }}
                    className="shadow-[inset_0_1px_4px_rgba(0,0,0,0.07)] flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 sm:text-base sm:p-4 sm:space-x-4 rtl:space-x-reverse"
                  >
                    {reminderInfo.map((item, index) => (
                      <li
                        key={index}
                        className={`flex items-center ${item.isDone ? "text-green-600" : "text-gray-600"}`}
                      >
                        {item.isDone ? (
                          <span className="mr-2 flex items-center justify-center w-6 h-6 bg-green-100 rounded-full shrink-0">
                            <svg
                              className="w-2.5 h-2.5 text-green-600 lg:w-4 lg:h-4 dark:text-blue-300"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 16 12"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 5.917 5.724 10.5 15 1.5"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span
                            className={`flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-600 rounded-full shrink-0 dark:border-blue-500`}
                          >
                            {item.id}
                          </span>
                        )}

                        {item.title}
                        {item.id < reminderInfo.length && (
                          <svg
                            className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 12 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m7 9 4-4-4-4M1 9l4-4-4-4"
                            />
                          </svg>
                        )}
                      </li>
                    ))}
                  </ol>

                </div>
              </div>}

              <div className="flex items-center justify-between  px-4 pt-4 pb-2 min-w-0">
                <h1 className="text-medium font-bold tracking-tight text-gray-900">Shared Balances</h1>
              </div>

              {getCurrentComponent()}
            </div>
          </div>
        </main >
      </div >
    </>
  );
};

export default Home;