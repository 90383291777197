import React from "react";

import { useNavigate } from "react-router-dom";
import { gql, useMutation, ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import Header from "../Header";
import { UserContext } from "../../Context";
import { User } from "../user/User.type";
import Expenses from "../transactions/ByExpenses";


const ListExpenses: React.FC = () => {
    const navigate = useNavigate();
    const context = React.useContext(UserContext)
    const user: User = context.user;
  

    return (
      <div className="min-h-full">
          <Header />
          <main>
            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
              <div className="lg:flex lg:items-center lg:justify-between">
                <div className="flex-1 min-w-0">
                  <h1 className="text-2xl px-4 font-bold tracking-tight text-gray-900">Expenses</h1>
                  
                  <div className="pt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                    <Expenses />
                  </div>
  
                </div>
              </div> 
              
            </div>
          </main>
  
        </div>
    );
  };
  
  export default ListExpenses;