import React from 'react'
import { useNavigate } from "react-router-dom";
import PaymentMethods from '../PaymentMethods';
import { User, UserPaymentMethods } from "./User.type";
import { UserContext } from "../../Context";
import { gql, useMutation, ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";




const MUTATION = gql`
mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      id
      countryCode
      name
      phone
      paymentMethods
      onBoardingStatus
    }
    success
    code
    message
  }
}
`;


const PaymentDetails: React.FC = () => {
    const navigate = useNavigate();
    const context = React.useContext(UserContext)
    const user: User = context.user;

    const [message, setMessage] = React.useState("");
    const [countryCode] = React.useState(user.countryCode);
    const [name, setName] = React.useState(user.name);
    const [phone, setPhone] = React.useState(user.phone);

    const [updateUser] = useMutation(MUTATION);

    const updateUserF = async (onBoardingStatus: any) => {

        let dataEvent;

        try {
            dataEvent = await updateUser({
                variables: {
                    input: {
                        countryCode,
                        name,
                        phone,
                        paymentMethods: paymentMethodList,
                        onBoardingStatus
                    }
                },
            });
        } catch (e) {
            (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
                setMessage(graphQLError.message)
            );
        }

        if (dataEvent?.data?.updateUser) {
            context.updateUser(dataEvent?.data?.updateUser.user)
            setMessage("");
            navigate("/", { replace: true });
        }
    };

    const userPaymentMethods: UserPaymentMethods = user.paymentMethods

    const [paymentMethodList, setPaymentMethodList] = React.useState(userPaymentMethods)

    return (
        <div className='flex min-h-full flex-col justify-center px-6 py-12'>
            <div className='py-3'>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 mb-6">
                    Enter your Payment Details
                </h2>
                <label htmlFor="paymentmethod" className="block text-md font-medium leading-6 text-gray-800 my-2">
                    Payment Method
                </label>
                <div className="mt-2 ml-2">
                    <PaymentMethods paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />
                </div>
            </div>
            <div className="flex flex-row space-x-2 mt-4">
                <button
                    type="button"
                    onClick={() => {
                        let updatedPaymentDetails = { ...user.onBoardingStatus, paymentDetails: false };
                        updateUserF(updatedPaymentDetails)
                    }}
                    className="flex w-full color-indigo-600 justify-center rounded-md bg-white-600 px-3 py-1.5 text-sm font-semibold border border-indigo-600 leading-6  text-indigo-600 shadow-sm"
                >
                    Skip
                </button>
                <button
                    type="button"
                    onClick={() => {
                        if (paymentMethodList) {
                            let updatedPaymentDetails = { ...user.onBoardingStatus, paymentDetails: true };
                            updateUserF(updatedPaymentDetails)
                        }
                        else {
                            let updatedPaymentDetails = { ...user.onBoardingStatus, paymentDetails: false };
                            updateUserF(updatedPaymentDetails)
                        }

                    }}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Next
                </button>
            </div>
        </div>
    )
}

export default PaymentDetails