import React, { useEffect } from "react";
import { User, UserPaymentMethods } from "./User.type";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import Header from "../Header";
import { UserContext } from "../../Context";
import PaymentMethods from "../PaymentMethods"


const MUTATION = gql`
mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      id
      countryCode
      name
      phone
      paymentMethods
    }
    success
    code
    message
  }
}
`;

const UserProfile: React.FC = () => {
  const navigate = useNavigate();
  const context = React.useContext(UserContext)
  const user: User = context.user;

  const countryCodeOptions = context.countryCodes;
  const userPaymentMethods: UserPaymentMethods = user.paymentMethods

  const [countryCode] = React.useState(user.countryCode);
  const [name, setName] = React.useState(user.name);
  const [phone, setPhone] = React.useState(user.phone);
  const [paymentMethodList, setPaymentMethodList] = React.useState(userPaymentMethods)

  const [message, setMessage] = React.useState("");
  const [updateUser] = useMutation(MUTATION);


  const updateUserF = async () => {
    let dataEvent;

    try {
      dataEvent = await updateUser({
        variables: {
          input: {
            countryCode,
            name,
            phone,
            paymentMethods: paymentMethodList
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }

    if (dataEvent?.data?.updateUser) {
      // console.log(dataEvent?.data?.updateUser);
      // console.log(context.user);
      context.updateUser(dataEvent?.data?.updateUser.user)
      setMessage("");
      navigate("/");
    }
  };


  return (
    <div className="min-h-full">
      <Header />
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="flex-1 px-4 min-w-0">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900">Profile</h1>
              <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" action="#" method="POST">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        id="name"
                        name="name"
                        type="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoComplete="name"
                        required
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="flex items-center justify-between">
                      <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                        Phone
                      </label>
                    </div>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center">
                        <label htmlFor="country" className="sr-only">
                          Country
                        </label>
                        <select
                          id="country"
                          name="country"
                          value={user.countryCode}
                          autoComplete="country"
                          className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500  sm:text-sm"
                        >
                          {countryCodeOptions?.map((country, index) => {
                            if (!country.isActive) return
                            return (
                              <option value={country.countryCode}>{country.shortName}</option>
                            )
                          })}

                        </select>
                      </div>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="(555) 987-6543"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="paymentmethod" className="block text-sm font-medium leading-6 text-gray-900">
                      Payment Method
                    </label>
                    <div className="mt-2">
                      <PaymentMethods paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />
                    </div>
                  </div>

                  <div>
                    <button
                      type="button"
                      onClick={updateUserF}
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Update
                    </button>
                  </div>
                </form>

              </div>

            </div>
          </div>

        </div>
      </main>

    </div>
  );
};

export default UserProfile;
