import React, { useRef } from "react";
import { gql, useQuery, useMutation, ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";

import { CountryCode, UserContext, } from "../../Context";
import { useNavigate } from "react-router-dom";
import { User } from "../user/User.type";
import SimpleReactValidator from 'simple-react-validator';
import VerifyCode from "./VerifyCode";
import { QUERY } from "../../Country";
import Loading from "../Loading";

const MUTATION = gql`
  mutation SignInWithPhoneNumber($input: SignInWithPhoneNumberInput!) {
    signInWithPhoneNumber(input: $input) {
      id
    }
  }
`;


const Login: React.FC = () => {
  const navigate = useNavigate();
  const { loading, data } = useQuery(QUERY)
  const context = React.useContext(UserContext);
  const [countryCodeOptions, setCountryCodeOptions] = React.useState<CountryCode[]>([]);
  const [message, setMessage] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const simpleValidator = useRef(new SimpleReactValidator())
  const [verifyCode, setVerifyCode] = React.useState(false);

  const [countryCode, setCountryCode] = React.useState(1);
  const [signInWithPhoneNumber] = useMutation(MUTATION);
  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    if (data?.countries) {
      setCountryCodeOptions(data.countries)
      context.updateCountryCodes(data.countries)
    }
  }, [data])

  function useForceUpdate() {
    const [value, setValue] = React.useState(0);
    return () => setValue(value => value + 1);
  }

  const loginF = async () => {

    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate();
      return;
    }

    let dataUser;

    try {
      dataUser = await signInWithPhoneNumber({
        variables: {
          input: {
            phone,
            countryCode,
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }

    if (dataUser?.data?.signInWithPhoneNumber) {

      setMessage("We’ve just sent a 6-digit verification code. It may take a moment to arrive.");
      localStorage.setItem("userId", dataUser.data.signInWithPhoneNumber.id);
      setVerifyCode(true);

      // navigate("/verifyCode");
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      loginF();
    }
  };

  function handlePhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    setVerifyCode(false);
    setPhone(e.target.value.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', ''));
    setMessage("We’ll send a verification code to this number.");
  }
  // if (loading) return <Loading />

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt="Your Company"
        /> */}
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in or Sign up
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6" >
            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                  Enter your Phone
                </label>
              </div>
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <label htmlFor="country" className="sr-only">
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    onChange={(e) => {
                      setCountryCode(parseInt(e.target.value))
                    }}
                    value={countryCode}
                    autoComplete="country"
                    className="h-full rounded-md border-0 bg-transparent pl-3 pr-7 text-gray-500 focus:outline-none text-sm"
                  >
                    {countryCodeOptions?.map((country, index) => {
                      if (!country.isActive) return
                      return (
                        <option value={country.countryCode}>{country.shortName}</option>
                      )
                    })}

                  </select>
                </div>
                <input
                  id="phone"
                  name="phone"
                  type="phone"
                  pattern="\d*"
                  value={phone}
                  onChange={handlePhoneChange}
                  required
                  placeholder="E.g. 5085551234"
                  onBlur={() => simpleValidator.current.showMessageFor('phone')}
                  className="px-2 block w-full rounded-md border-1.5 pl-16 py-2 text-gray-900 ring-1 ring-gray-700 placeholder:text-gray-400 text-sm sm:leading-6"
                />
              </div>
              <div className="mt-2 text-sm text-red-600">
                {simpleValidator.current.message('phone', phone, 'required|phone', { className: ' ' })}
              </div>
              {message && message !== "" && (
                <div className="mt-2 text-sm text-gray-600">
                  {message}
                </div>
              )}
            </div>
            {verifyCode && (
              <VerifyCode />
            )}
            {!verifyCode && (
              <div>
                <button
                  type="button"
                  onClick={loginF}
                  className="mt-4 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Login
                </button>
              </div>
            )}



          </div>

        </div>
      </div>
    </>
  );
};

export default Login;
