import React from "react"
import { gql, useQuery } from "@apollo/client"
import { UserContext } from "./Context"
import Loading from "./pages/Loading"


export const QUERY = gql`
    query Country {
  countries {
    id
    name
    shortName
    countryCode
    isActive
  }
}`


const Country: React.FC = () => {
  const { loading, data } = useQuery(QUERY)
  const context = React.useContext(UserContext)

  React.useEffect(() => {
    if (data?.countries) {
      context.updateCountryCodes(data.countries)
    }
  }, [data])

  if (loading) return <Loading />

  return null
}

export default Country;