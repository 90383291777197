export interface User {
  id: string;
  name?: string;
  phone: string;
  countryCode: number;
  contacts: User[];
  inviteCode: string;
  netAmountOwed: number;
  connections: Connection[];
  settlements: Settlement;
  paymentMethods: UserPaymentMethods;
  onBoardingStatus: {
    [key: string]: boolean;
  };
}

export const userClass: User = {
  id: "",
  name: "",
  phone: "",
  countryCode: 1,
  contacts: [],
  inviteCode: "",
  netAmountOwed: 0,
  connections: [],
  settlements: {
    amountPayable: 0,
    amountReceivable: 0,
    netAmountOwed: 0,
  },
  paymentMethods: {},
  onBoardingStatus: {},
};

export interface Settlement {
  amountPayable: number;
  amountReceivable: number;
  netAmountOwed: number;
}

export interface Connection {
  contact: User;
  settlements: Settlement;
}

export interface UserPaymentMethods {
  paypal?: { emailornumber: string };
  venmo?: { username: string };
  zelle?: { emailornumber: string };
  cashapp?: { username: string };
  applepay?: { emailornumber: string };
  googlepay?: { emailornumber: string };
  other?: CustomPaymentMethod[];
}

interface CustomPaymentMethod {
  name: string;
  value: string;
};


