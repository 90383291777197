import React from 'react';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";

import Me from "./Me";
import Country from "./Country";

import { Breadcrumb, UserContext, userContextDefaultValue, CountryCode } from "./Context";
import RouteApp from "./route/RouteApp";
import { User } from "./pages/user/User.type"
import { useLocalStorage } from "./LocalStorage"

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MTMP68NQ',
  events: {
    sendUserInfo: 'userInfo'
  }
}

TagManager.initialize(tagManagerArgs)


const App: React.FC = () => {
  const [user, setUser] = React.useState(userContextDefaultValue.user);
  const [breadcrumbs, setBreadcrumbs] = React.useState(userContextDefaultValue.breadcrumbs);
  const [countryCodes, setCountryCodes] = React.useState(userContextDefaultValue.countryCodes);

  const [config, setConfig] = useLocalStorage("config");


  const updateUser = React.useCallback(
    (user: User) => {
      setUser(user);
    },
    [setUser, user]
  );

  const updateCountryCodes = React.useCallback(
    (countryCodes: CountryCode[]) => {
      setCountryCodes(countryCodes);
    },
    [setCountryCodes, countryCodes]
  );



  const updateBreadcrumbs = React.useCallback(
    (breadcrumbs: Breadcrumb[]) => {
      setBreadcrumbs(breadcrumbs);
    },
    [setBreadcrumbs, breadcrumbs]
  );

  const updateConfig = React.useCallback(
    (config: { groupBy: string }) => {
      setConfig(config);
    },
    [setConfig, config]
  );

  function getConfig(): { groupBy: string } {
    if (config() === null) {
      return { groupBy: 'Users' };
    }
    return config();
  }

  return (
    <UserContext.Provider value={{ user, updateUser, config: getConfig(), countryCodes, updateCountryCodes, updateConfig, afterLogin: window.location.pathname, breadcrumbs, updateBreadcrumbs, paymentMethod: userContextDefaultValue.paymentMethod }}>
      <Router>
        <Me />
        <Country />
        <RouteApp />
      </Router>
    </UserContext.Provider>
  );
}

export default App;
