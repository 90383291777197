import React, { useRef } from "react";
import { gql, useMutation, ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";

import { UserContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';

const MUTATION = gql`
  mutation VerifyPhoneNumber($input: VerifyPhoneNumberInput!) {
  verifyPhoneNumber(input: $input) {
    user {
      countryCode
      id
      name
      phone
    }
    token
    success
    message
    code
  }
}
`;

const VerifyCode: React.FC = () => {
  const navigate = useNavigate();
  const context = React.useContext(UserContext);
  const [message, setMessage] = React.useState("");
  const [code, setCode] = React.useState("");
  const simpleValidator = useRef(new SimpleReactValidator())


  const [VerifyPhoneNumber] = useMutation(MUTATION);
  const forceUpdate = useForceUpdate();

  function useForceUpdate(){
    const [value, setValue] = React.useState(0);
    return () => setValue(value => value + 1);
  }
  const verifyPhoneF = async () => {

    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate();
      return;
    }

    let dataUser;
    try {
      dataUser = await VerifyPhoneNumber({
        variables: {
          input: {
            id: Number(localStorage.getItem("userId")),
            code,
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }

    if (dataUser?.data?.verifyPhoneNumber) {
        if(dataUser?.data?.verifyPhoneNumber.code == "500")
        {
            setMessage("Invalid Code");      
            return;
        }

        setMessage("");
        localStorage.setItem("authToken", dataUser.data.verifyPhoneNumber.token);
        context.updateUser(dataUser.data.verifyPhoneNumber.user);
        if(dataUser.data.verifyPhoneNumber.user.name === ""
        || dataUser.data.verifyPhoneNumber.user.name === null)
        {
            navigate("/onboarding");
            return;
        }
        navigate(context.afterLogin);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
        verifyPhoneF();
    }
  };

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label htmlFor="code" className="block text-sm font-medium leading-6 text-gray-900">
              Enter the Verification Code
            </label>
            <div className="mt-2">
              <input
                id="code"
                name="code"
                type="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                onBlur={() => simpleValidator.current.showMessageFor('code')}
                required
                className="block w-full rounded-md border-1.5 py-1.5 px-2 text-gray-900 ring-1 ring-gray-700 placeholder:text-gray-400 text-sm sm:leading-6"
              />
              <div className="mt-2 text-sm text-red-600">
                {simpleValidator.current.message('code', code, 'required', { className: '' })}
              </div>
              {message && (
                <div className="mt-2 text-sm text-red-600">
                  {message}
                </div>
              )}
            </div>
          </div>
          <div>
            <p className="mt-4 text-xs leading-4 text-gray-400">
              By clicking I AGREE, you agree to our Terms and Privacy Policy and consent to receive text messages from us and hosts. Message and data rates apply. Text HELP for help and STOP to cancel.
            </p>
            <button
              type="button"
              onClick={verifyPhoneF}
              className="mt-4 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              I Agree
            </button>
          </div>
        </div>
        
      </div>
      
    </>
  );
};

export default VerifyCode;
