import React, { useEffect, useState } from 'react';
import { UserContext, PaymentMethod } from "../Context";
import { UserPaymentMethods } from "./user/User.type";
import { isConstructorDeclaration } from 'typescript';


interface PropsType {
    setPaymentMethodList: React.Dispatch<React.SetStateAction<{}>>;
    paymentMethodList: UserPaymentMethods;
    index?: number;
}

const PaymentMethods: React.FC<PropsType> = ({ setPaymentMethodList, paymentMethodList }) => {

    const context = React.useContext(UserContext)
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [components, setComponents] = useState<React.ReactNode[]>([]);

    useEffect(() => {
        function getIsChecked() {
            let copy = [...context.paymentMethod];
            copy.forEach((item) => {
                if (paymentMethodList && Object.keys(paymentMethodList).length !== 0) {
                    if (item?.label.replace(/ /g, "").toLowerCase() in paymentMethodList) {
                        item.isChecked = true
                    }
                }

            })
            setPaymentMethods(copy);
        }
        getIsChecked();


        // to render the other components only when other data is present 
        let copyCustom = [...(paymentMethodList?.other ?? [])];
        const newComponents = copyCustom?.map((item, index) => {
            return [...components, <CustomMethod index={index} paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />];
        });
        setComponents(newComponents);

    }, [])

    const handleCheckboxChange = (id: number) => {
        setPaymentMethods(prevState =>
            prevState.map(method =>
                method.id === id ? { ...method, isChecked: !method.isChecked } : method
            )
        );
    };


    return (
        <div className="space-y-1">
            {paymentMethods.map(method => (
                <div key={method.id} className="flex flex-col">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={method.isChecked}
                            onChange={() => {
                                console.log("yahan tak aaraha hy")
                                handleCheckboxChange(method.id)
                            }}
                        />
                        <img className='ml-3 mr-0 h-4 w-4' src={method.image} alt="image" />
                        <span className="ml-2 block text-sm font-medium leading-6 text-gray-900">{method.label}</span>
                    </label>
                    {method.isChecked && (
                        <div className="my-2">
                            {method.label === 'PayPal' && <PayPalComponent paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />}
                            {method.label === 'Venmo' && <VenmoComponent paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />}
                            {method.label === 'Zelle' && <ZelleComponent paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />}
                            {method.label === 'Cash App' && <CashAppComponent paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />}
                            {method.label === 'Apple Pay' && <ApplePayComponent paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />}
                            {method.label === 'Google Pay' && <GoogleAppComponent paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />}
                            {method.label === 'Other' && (
                                <div>
                                    {components.length === 0 ? (
                                        <CustomMethod index={components.length} paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />
                                    ) : (
                                        <div className='mt-1'>
                                            {components.map((component, index) => (
                                                <div key={index}>{component}</div>
                                            ))}
                                        </div>
                                    )}
                                    <div className="flex justify-center mt-2">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (components.length == 0) {
                                                    // when button clicked for the first time array would be empty so we have to push two other components 
                                                    const firstComponent = [...components, <CustomMethod index={0} paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />];
                                                    const secondComponent = [...firstComponent, <CustomMethod index={1} paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />];
                                                    setComponents(secondComponent);

                                                } else {
                                                    const newComponents = [...components, <CustomMethod index={components.length} paymentMethodList={paymentMethodList} setPaymentMethodList={setPaymentMethodList} />];
                                                    setComponents(newComponents);
                                                }
                                            }}
                                            className="justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            + Add More
                                        </button>
                                    </div>
                                </div>
                            )}

                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
export default PaymentMethods;

const PayPalComponent: React.FC<PropsType> = ({ setPaymentMethodList, paymentMethodList }) => {

    const [emailOrNumber, setEmailOrNumber] = React.useState(paymentMethodList?.paypal?.emailornumber)

    return <div className='flex justify-center flex-col'>
        <input
            type="text"
            name="paypal-email"
            id="paypal-email"
            placeholder='Enter your email or phone number'
            value={emailOrNumber}
            onChange={(e) => {
                let obj = {
                    paypal: {
                        emailornumber: e.target.value,
                        image: "https://w7.pngwing.com/pngs/632/1015/png-transparent-paypal-logo-computer-icons-payment-paypal-blue-angle-service-thumbnail.png"
                    }
                }
                setPaymentMethodList({ ...paymentMethodList, ...obj })
                setEmailOrNumber(e.target.value)
            }}
            className="block rounded-md mb-2 border border-gray-300 py-1 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
    </div>
};

const VenmoComponent: React.FC<PropsType> = ({ setPaymentMethodList, paymentMethodList }) => {

    const [username, setUsername] = React.useState(paymentMethodList?.venmo?.username)

    return <div className='flex justify-center flex-col'>
        <input
            type="text"
            name="venmo-username"
            id="venmo-username"
            placeholder='Enter your username'
            value={username}
            onChange={(e) => {
                let obj = {
                    venmo: {
                        username: e.target.value,
                        image: "https://upload.wikimedia.org/wikipedia/commons/8/84/Venmo_logo.png"
                    }
                }
                setPaymentMethodList({ ...paymentMethodList, ...obj })
                setUsername(e.target.value)
            }}
            className="block rounded-md mb-2 border border-gray-300 py-1 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
    </div>
};

const ZelleComponent: React.FC<PropsType> = ({ setPaymentMethodList, paymentMethodList }) => {

    const [emailOrNumber, setEmailOrNumber] = React.useState(paymentMethodList?.zelle?.emailornumber)

    return <div className='flex justify-center flex-col'>
        <input
            type="text"
            name="zelle-email"
            id="zelle-email"
            placeholder='Enter your email or phone number'
            value={emailOrNumber}
            onChange={(e) => {
                let obj = {
                    zelle: {
                        emailornumber: e.target.value,
                        image: "https://freelogopng.com/images/all_img/1659810741zelle-app-logo.png"
                    }
                }
                setPaymentMethodList({ ...paymentMethodList, ...obj })
                setEmailOrNumber(e.target.value)
            }}
            className="block mb-2 rounded-md border border-gray-300 py-1 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
    </div>;
};

const CashAppComponent: React.FC<PropsType> = ({ setPaymentMethodList, paymentMethodList }) => {

    const [username, setUsername] = React.useState(paymentMethodList?.cashapp?.username)
    return <div className='flex justify-center flex-col'>
        <input
            type="text"
            name="cashapp-username"
            id="cashapp-username"
            placeholder='Enter your username'
            value={username}
            onChange={(e) => {
                let obj = {
                    cashapp: {
                        username: e.target.value,
                        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Square_Cash_app_logo.svg/1200px-Square_Cash_app_logo.svg.png"
                    }
                }
                setPaymentMethodList({ ...paymentMethodList, ...obj })
                setUsername(e.target.value)
            }}
            className="block rounded-md mb-2 border border-gray-300 py-1 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
    </div>
};

const ApplePayComponent: React.FC<PropsType> = ({ setPaymentMethodList, paymentMethodList }) => {

    const [emailOrNumber, setEmailOrNumber] = React.useState(paymentMethodList?.applepay?.emailornumber)

    return <div className='flex justify-center flex-col'>
        <input
            type="text"
            name="applepay-email"
            id="applepay-email"
            placeholder='Enter your email or phone number'
            value={emailOrNumber}
            onChange={(e) => {
                let obj = {
                    applepay: {
                        emailornumber: e.target.value,
                        image: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
                    }
                }
                setPaymentMethodList({ ...paymentMethodList, ...obj })
                setEmailOrNumber(e.target.value)
            }}
            className="block rounded-md mb-2 border border-gray-300 py-1 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
    </div>
};

const GoogleAppComponent: React.FC<PropsType> = ({ setPaymentMethodList, paymentMethodList }) => {

    const [emailOrNumber, setEmailOrNumber] = React.useState(paymentMethodList?.googlepay?.emailornumber)

    return <div className='flex justify-center flex-col'>
        <input
            type="text"
            name="googleapp-email"
            id="googleapp-email"
            placeholder='Enter your email or phone number'
            value={emailOrNumber}
            onChange={(e) => {
                let obj = {
                    googlepay: {
                        emailornumber: e.target.value,
                        image: "https://play-lh.googleusercontent.com/aFWiT2lTa9CYBpyPjfgfNHd0r5puwKRGj2rHpdPTNrz2N9LXgN_MbLjePd1OTc0E8Rl1"
                    }
                }
                setPaymentMethodList({ ...paymentMethodList, ...obj })
                setEmailOrNumber(e.target.value)
            }}
            className="block rounded-md mb-2 border border-gray-300 py-1 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
    </div>
};


const CustomMethod: React.FC<PropsType> = ({ setPaymentMethodList, paymentMethodList, index }) => {

    const paymentMethod = paymentMethodList?.other ? paymentMethodList?.other[index ? index : 0] : null;

    const [name, setName] = React.useState(paymentMethod?.name)
    const [value, setValue] = React.useState(paymentMethod?.value)


    const handleNameChange = (e: any) => {
        const newName = e.target.value;
        const newObj = { name: newName, value: value ?? "" }
        const updatedCustom = paymentMethodList.other?.map((item, i) => {
            if (i === index) {
                return { ...item, name: newName };
            }
            return item;

        });

        const isExisting = updatedCustom?.find(item => item.value === newObj.value && item.name === newObj.name);

        // If newObj doesn't exist, insert it into the other array
        if (!isExisting && newObj.name !== "" && newObj.value !== "") {
            updatedCustom?.push(newObj);
        }
        if (paymentMethodList.other?.length) {
            setPaymentMethodList({
                ...paymentMethodList,
                other: updatedCustom
            });
        } else {
            setPaymentMethodList({
                ...paymentMethodList,
                other: [newObj]
            });
        }
        setName(newName);
    };

    const handlevalueChange = (e: any) => {
        const newValue = e.target.value;
        const newObj = { name: name ?? "", value: newValue }

        const updatedCustom = paymentMethodList.other?.map((item, i) => {
            if (i === index) {
                return { ...item, value: newValue };
            }
            return item;
        });

        const isExisting = updatedCustom?.find(item => item.value === newObj.value && item.name === newObj.name);

        // If newObj doesn't exist, insert it into the other array
        if (!isExisting && newObj.name !== "" && newObj.value !== "") {
            updatedCustom?.push(newObj);
        }

        if (paymentMethodList.other?.length) {
            setPaymentMethodList({
                ...paymentMethodList,
                other: updatedCustom
            });
        } else {
            setPaymentMethodList({
                ...paymentMethodList,
                other: [newObj]

            });
        }
        setValue(newValue);
    };

    return <div className='flex justify-center flex-row space-x-2'>
        {/* <label htmlFor="bankDetails" className="flex block text-sm font-medium leading-6 text-gray-900 mb-1 justify-center items-center">
            Enter Details
        </label> */}

        <input
            type="text"
            name="other-name"
            id="other-name"
            placeholder='Name'
            value={name}
            onChange={handleNameChange}
            className="overflow-hidden rounded-md mb-2 border border-gray-300 py-1 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
        <input
            type="text"
            name="other-value"
            id="other-value"
            placeholder='Value'
            value={value}
            onChange={handlevalueChange}
            className="overflow-hidden rounded-md mb-2 border border-gray-300 py-1 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
    </div>
};