import React, { useEffect, useRef } from "react";
import { UserContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import { Expense, ExpenseUser, Transaction } from "../expense/Expense.type";
import { Fragment, useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { FaceFrownIcon, GlobeAmericasIcon } from '@heroicons/react/24/outline'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { ApolloError, from, gql, useMutation } from "@apollo/client";
import { User } from "../user/User.type";
import { GraphQLError } from "graphql";


export interface Settlement {
  amount?: number,
  fromUser?: User,
  toUser?: ExpenseUser,
  transaction?: Transaction,
}

const SETTLETRANSACTIONMUTATION = gql`
mutation SettleTransaction($input: SettleTransactionInput!) {
  settleTransaction(input: $input) {
    code
    message
    success
    transaction {
      id
    }
  }
}
`;

const SETTLEALLTRANSACTIOSNMUTATION = gql`
mutation SettleAllTransactionBetweenUsers($input: SettleAllTransactionBetweenUsersInput!) {
  settleAllTransactionBetweenUsers(input: $input) {
    code
    message
    success
    transactions {
      id
    }
    fromUser {
      id
      name
      phone
      contacts {
        name
        phone
        id
        countryCode
      }
      connections {
        contact {
          id
          name
          phone
          countryCode
        }
        settlements {
          netAmountOwed
          amountReceivable
          amountPayable
        }
      }
      settlements {
        netAmountOwed
        amountReceivable
        amountPayable
      }
    }
  }
}
`;

function SettleTransactions(props: { settlement: Settlement, notify: (message: string) => void }) {
  const [show, setShow] = useState(true)
  const [message, setMessage] = useState("")
  const context = React.useContext(UserContext);
  const user: User = context.user;

  const [settleAllTransactionBetweenUsers] = useMutation(SETTLEALLTRANSACTIOSNMUTATION);
  const [settleTransaction] = useMutation(SETTLETRANSACTIONMUTATION);

  const [paymentMethod, setPaymentMethod] = useState("CASH")
  const [amount, setAmount] = useState(props.settlement.transaction?.amountOwed || props.settlement.amount)

  const [sendNotification, setSendNotification] = useState<boolean>(false)


  const paymentMethods = [
    { value: "CASH", label: "Cash" },
    { value: "BANK_TRANSFER", label: "Bank Transfer" },
    { value: "PAYPAL", label: "PayPal" },
    { value: "VENMO", label: "Venmo" },
    { value: "ZELLE", label: "Zelle" },
    { value: "CASH_APP", label: "Cash App" },
    { value: "APPLE_PAY", label: "Apple Pay" },
    { value: "GOOGLE_PAY", label: "Google App" },
    { value: "OTHER", label: "Other" },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
  console.log("=======sendNotification=====sendNotification", sendNotification)

  const settleAllTransactionsR = async () => {
    let dataSettle;

    try {
      dataSettle = await settleAllTransactionBetweenUsers({
        variables: {
          input: {
            fromUserId: props.settlement.fromUser?.id,
            toUserId: props.settlement.toUser?.id,
            paymentMethod: paymentMethod,
            sendNotification
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) => {
        console.log("------err------", graphQLError.message);
        setMessage(graphQLError.message)

      }
      );
    }
    if (dataSettle?.data?.settleAllTransactionBetweenUsers) {
      setShow(false);
      context.updateUser(dataSettle?.data?.settleAllTransactionBetweenUsers?.fromUser as User);
      props.notify("success");
    }

  };


  const settleTransactionR = async () => {
    let dataSettle;

    try {
      dataSettle = await settleTransaction({
        variables: {
          input: {
            id: props.settlement.transaction?.id,
            amountPaid: amount,
            paymentMethod: paymentMethod,
          }
        },
      });
    } catch (e) {
      (e as ApolloError).graphQLErrors.some((graphQLError: GraphQLError) =>
        setMessage(graphQLError.message)
      );
    }
    if (dataSettle?.data?.settleTransaction) {
      setShow(false);
      props.notify("success");

    }
  };

  function handleSettleTransaction() {
    if (props.settlement.transaction?.id) {
      settleTransactionR();
    }
    else {
      settleAllTransactionsR();
    }
  }

  return (
    <Transition.Root show={show} as={Fragment} appear>
      <Dialog as="div" className="relative z-10" onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white p-6 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <div>
                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                  From: {props.settlement.fromUser?.name} to {props.settlement.toUser?.name}
                </label>
              </div>

              <div className="pt-4">
                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                  Amount to Settle
                </label>
                <input
                  id="amountToSettle"
                  name="amountToSettle"
                  type="number"
                  pattern="\d*"
                  value={amount}
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="pt-4">
                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                  Payment Method
                </label>
                <select
                  id="paymentMethod"
                  name="paymentMethod"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  {paymentMethods.map((paymentMethod) => (
                    <option key={paymentMethod.value} value={paymentMethod.value}>
                      {paymentMethod.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-3">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={sendNotification}
                    onChange={() => setSendNotification(!sendNotification)}
                  />

                  <span className="ml-2 block text-sm font-medium leading-6 text-gray-800">Send {props.settlement.toUser?.name} a notification</span>
                </label>
              </div>
              <div className="mt-6">
                <button
                  type="button"
                  onClick={handleSettleTransaction}
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Mark As Settled
                </button>
              </div>
              <div className="mt-2">
                <button
                  type="button"
                  onClick={() => { setShow(false); props.notify("cancel"); }}
                  className="flex w-full justify-center rounded-md border border-slate-400 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}


export default SettleTransactions;