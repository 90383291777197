import React from "react"
import { gql, useQuery } from "@apollo/client"
import { UserContext } from "./Context"
import Loading from "./pages/Loading"

interface Props {
  isRefetch?: boolean;
  setIsRefetch?: React.Dispatch<React.SetStateAction<boolean>>
}

export const QUERY = gql`
  query Me {
    me {
      id
      name
      phone
      countryCode
      paymentMethods
      contacts {
        name
        phone
        id
        countryCode
        paymentMethods
      }
      onBoardingStatus
      connections {
        contact {
          id
          name
          phone
          countryCode
        }
        settlements {
          netAmountOwed
          amountReceivable
          amountPayable
        }
      }
      settlements {
        netAmountOwed
        amountReceivable
        amountPayable
      }
    }
  }
`



const Me: React.FC<Props> = ({ isRefetch, setIsRefetch }) => {
  const { loading, data, refetch } = useQuery(QUERY)
  const context = React.useContext(UserContext)

  React.useEffect(() => {
    if (isRefetch) {
      refetch();
      setIsRefetch && setIsRefetch(false)
    }
    if (data?.me?.id) {
      context.updateUser(data.me)
    }
  }, [data])

  if (loading) return <Loading />

  return null
}

export default Me;




