import React, { useState } from "react";
import { UserContext } from "../Context";

import { useNavigate } from "react-router-dom";
import { userClass } from "./user/User.type";
import { gql, useQuery } from "@apollo/client"
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid'
import ExpenseButton from "./ExpenseButton";


const Header: React.FC = () => {
    const context = React.useContext(UserContext);
    const navigate = useNavigate();
 
    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    const logout = async () => {
        navigate("/");
        localStorage.setItem("authToken", "");
        context.updateUser(userClass);
    };
    
    const profile = async () => {
        navigate(`/userProfile`);  
    };

    const home = async () => {
      navigate(`/`);  
  };

    const createEvent = async () => {
      navigate(`/createEvent`);  
  };

    const expenses = async () => {
      navigate(`/expense/all`);  
    }


      const userNavigation = [
        { name: 'Home', href: `/`, onClick:home },
        { name: 'Profile', href: `/user/${context.user.id}`, onClick:profile },
        { name: 'Expenses', href: `/expense/all`, onClick:expenses },
        { name: 'Sign out', href: '/logout', onClick:logout },
      ]

    return (
        <>
        <Disclosure as="nav" className="bg-white shadow">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="-ml-2 mr-2 flex items-center md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="flex flex-shrink-0 items-center">

                    </div>  
                  </div>
                  <div className="flex items-center">
                    
                    <div className="hidden flex flex-1 justify-center pr-4 lg:ml-6 lg:justify-end">
                        <div className="w-full max-w-lg lg:max-w-xs">
                        <label htmlFor="search" className="sr-only">
                            Search
                        </label>
                        <div className="relative text-gray-400 focus-within:text-gray-600">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                            </div>
                            <input
                            id="search"
                            className="block w-full rounded-md border-1 border-slate-200 bg-white py-1.5 pl-10 text-gray-900 focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-indigo-300 text-sm"
                            placeholder="Search"
                            type="search"
                            name="search"
                            />
                        </div>
                        </div>
                    </div>
                    <div className="flex-shrink-0">
                      <ExpenseButton />
                    </div>
                    <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                      

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span className="sr-only">Open user menu</span>
                            <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                            </span>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                            {({ active }) => (
                                <a
                                  href="/"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  Home
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  Your Profile
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={logout}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="border-t border-gray-200 pb-3 pt-2">
                  <div className="space-y-1">
                    <Disclosure.Button
                      as="button"
                      onClick={home}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                    >
                      Home
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="button"
                      onClick={expenses}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                    >
                      Expenses
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="button"
                      onClick={profile}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                    >
                      Profile
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="button"
                      onClick={logout}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                    >
                      Sign out
                    </Disclosure.Button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        </>
    )
};

export default Header;


